// Tab menu styles
// =============================================================================
.tab-menu {
	position: relative;
	&.-jp-syll {
		margin-top: 1.5em;
	}
	.tmp19 .contlist_box + & {
		margin-top: 5 / 1.6 + 0em;
	}
	
	section + & {
  	margin-top: 2em;
	}
	
}

.tab-lst {
	line-height: 1.4;
	display: flex;
	&_itm {
		width: calc((100% - 2px * 4) / 5);
		&:nth-last-child(n+2) {
			margin-right: 2px;
		}
		&.-bn-itm {
			flex-shrink: 0;
			& hr {
				width: 2px;
				height: 100%;
				margin: 0 calc(1em / 1.7);
				border: 0;
				background-color: rgba($siteCol-k-blk, 0.15);
				display: block;
			}
		}
		.tmp19 & {
			width: calc((100% - 2px * 2) / 3)
		}
	}
	&_lnk {
		height: 100%;
		padding: calc(0.85em / 1.7) calc(0.5em / 1.7);
		color: $siteCol-f-wht !important;
		background: $siteCol-gly04;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		.-bn-itm & {
			
		}
		.is-actv & {
			background: $siteCol-grn01;
			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
			}
			&::before {
				width: 100%;
				height: 3px;
				background-color: $siteCol-rsj-grn;
				top: 100%;
				left: 0;
			}
			&::after {
				width: 0;
				height: 0;
				border: {
					style: solid;
					width: calc(0.5em / 1.7) calc(0.7em / 1.7) 0 calc(0.7em / 1.7);
					color: $siteCol-rsj-grn transparent transparent transparent;
				}
				top: calc(100% + 3px);
				left: $baseCenterPos;
				transform: translate(-#{$baseCenterPos}, 0);
			}
		}
		.is-dsbl & {
			background-color: $siteCol-gly04;
			&::before,
			&::after {
				display: none;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:first-child {
				min-width: calc(7.5em / 1.5);
				.tmp2 & {
					min-width: 0;
				}
			}
			&.-bn-itm {
				& hr {
					display: none;
				}
			}
		}
		&_wrp {
			.-bn-itm & {
				display: none;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&.-bn-itm {
				min-width: calc(20em / 1.7);
			}
		}
	}
}
a.tab-lst_lnk{
  	background: $siteCol-gly04;	
}

.bn-tgl {
	width: 1em;
	height: calc(0.2em / 1.4);;
	font-size: calc(1.4em / 1.7);
	background-color: $siteCol-f-wht;
	display: block;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: inherit;
		display: block;
		transform: rotate(-90deg);
		transition: transform $tglTime $easeOutQuint;
	}
	.is-shw & {
		&::before {
			transform: rotate(0);
		}
	}
}

.bak-num-lst {
	padding: calc(1em / 1.7) calc(1.5em / 1.7);
	line-height: 1.4;
	opacity: 0;
	visibility: hidden;
	display: flex;
	flex-wrap: wrap;
	border-radius: calc(0.5em / 1.7);
	background-color: $siteCol-f-wht;
	position: absolute;
	top: calc(100% + 3px + 1.2em / 1.7);
	left: 0;
	z-index: 100;
	transform: translate(0, -15%);
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	.is-shw & {
		visibility: visible;
		opacity: 1;
		transform: translate(0);
	}
	&_itm {
		margin: calc(0.6em / 1.7) calc(1.5em / 1.7);
	}
	&_lnk {
  	background: none !important;
		color: $siteCol-gly02 !important;
		font-weight: $fwNsrfJp-medium;
		display: flex;
		align-items: baseline;
		opacity: 1 !important;
		&::before {
			content: '\e907';
			margin-right: 1em;
		 	font: {
				family: icomoon;
				size: calc(1em / 1.7);
				weight: $fwNsnsJp-medium;
			}
			display: block;
			flex-shrink: 0;
			position: relative;
			top: calc(-0.35em / 1.7);
		}
		.is-actv & {
			color: $siteCol-pnk02 !important;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_lnk {
			transition: color $overTime $easeOutQuint !important;
			&:hover {
				color: $siteCol-pnk02 !important;
			}
		}
	}
}

.jp-syll-sct {
	display: none;
	&.is-shw {
		display: block;
	}
}
