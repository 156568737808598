// Modal styles
// =============================================================================
.mdl {
	width: 100vw;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9500;
	&.is-opn {
		opacity: 1;
		visibility: visible;
	}
	.is-load & {
		transition:
			opacity $mdlDur $easeOutQuint,
			visibility $mdlDur $easeOutQuint;
	}
	&_bdy {
		width: 90vw;
		max-width: 1000px;
		opacity: 0;
		position: relative;
		z-index: 10;
		transform: scale(0.75);
		.-pdf & {
			max-width: none;
		}
		.is-opn & {
			opacity: 1;
			transform: scale(1);
			.is-load & {
				transition:
					opacity $mdlDur $mdlDur / 1.8 $easeOutBack,
					transform $mdlDur $mdlDur / 1.8 $easeOutBack;
			}
		}
		.is-load & {
			transition:
				opacity $mdlDur $easeOutBack,
				transform $mdlDur $easeOutBack;
		}
	}
	&_pdf {
		width: 100%;
		border: 0;
	}
	&_cls {
		overflow: hidden;
		position: absolute;
		bottom: calc(100% + 1.2em);
		z-index: 15;
		cursor: pointer;
		transform: rotate(45deg);
		&::before,
		&::after {
			content: '';
			background-color: $siteCol-f-wht;
			opacity: 0;
			display: block;
			position: absolute;
			top: $baseCenterPos;
			left: $baseCenterPos;
			transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			.is-opn & {
				opacity: 1;
				.is-load & {
					transition:
						opacity $mdlDur $mdlDur * 1.5 $easeOutQuint,
						transform $mdlDur $mdlDur * 1.5 $easeOutQuint;
				}
			}
			.is-load & {
				transition:
					opacity $mdlDur $easeOutQuint,
					transform $mdlDur $easeOutQuint;
			}
		}
		&::before {
			width: 100%;
		}
		&::after {
			height: 100%;
		}
	}
	&_ovrly {
		width: 100%;
		height: 100%;
		background-color: $siteCol-k-blk;
		opacity: 0.75;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_bdy {
			.-pdf & {
				width: 95vw;
			}
		}
		&_pdf {
			height: 78vh;
		}
		&_cls {
			width: 27px;
			height: 27px;
			right: -1%;
			&::before {
				height: 3px;
			}
			&::after {
				width: 3px;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_bdy {
			.-pdf & {
				
			}
		}
		&_pdf {
			height: 85vh;
		}
		&_cls {
			width: 36px;
			height: 36px;
			left: 100%;
			&::before {
				height: 4px;
			}
			&::after {
				width: 4px;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_cls {
			right: -1%;
			left: auto;
		}
	}
}