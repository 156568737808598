// General icon styles
// =============================================================================
.icn {
	line-height: 0;
	display: block;
	flex-shrink: 0;
	&.-gen-s-icn {
		width: $icn-gen-s-w;
		margin-right: calc(0.8em / 1.3);
		align-self: flex-start;
		position: relative;
		top: calc(0.3em / 1.3);
		.lng-lst & {
			margin: 0;
			color: $siteCol-gly02;
			position: absolute;
			top: $baseCenterPos;
			right: calc(100% + 1em / 1.3);
			transform:
				translate(0, -#{$baseCenterPos})
				rotate(105deg);
			.gen-foot & {
				color: $siteCol-f-wht;
			}
		}
		.cnt-top-lst_dt & {
			margin: 0;
			position: absolute;
			top: $baseCenterPos;
			right: calc(100% + 0.5em / 1.3);
			transform:
				translate(0, -#{$baseCenterPos})
				rotate(105deg);
		}
		.cnt-lst_itm.-lv01 & {
			width: calc(1.1em / 1.5);
			margin-right: calc(0.8em / 1.5);
			top: calc(0.5em / 1.5);
		}
		.addr-tel & {
			margin: 0;
			position: absolute;
			top: calc(#{$baseCenterPos} - 0.1em / 1.3);
			right: calc(100% + 1em / 1.3);
			transform:
				translate(0, -#{$baseCenterPos})
				rotate(105deg);
		}
		.ua-ie-lgcy & {
			height: 1.1 / 1.3 + 0em;
		}
	}
	&.-arw-icn {
		width: $icn-arw-w;
		margin-left: calc(0.8em / 1.3);
		.ua-ie-lgcy & {
			height: 1.6 / 1.3 + 0em;
		}
	}
	&.-lst-arw-icn {
		width: $icn-lst-arw-w;
		.bc-lst & {
			margin: 0 2em;
			position: relative;
			top: calc(-0.1em / 1.3);
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-lst-arw-icn {
			.bc-lst & {
				margin: 0 1em;
			}
		}
	}
}

.icn-home-sld {
	font-size: 1.4em;
	background-image: none !important;
	&,
	&:hover {
		color: $siteCol-rsj-grn !important;	
	}
}
