@charset "UTF-8";
/* ==============================================================================================
   Reset styles
   ============================================================================================== */
/* Document
   ========================================================================== */
@-ms-viewport {
  width: device-width;
  initial-scale: 1; }

html {
  font-family: "Font Awesome 5 Free", "Roboto", "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", YuGothic, "游ゴシック体", "Yu Gothic", "游ゴシック", Meiryo, "メイリオ", Osaka, "MS PGothic", "ＭＳ Ｐゴシック", sans-serif;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1;
  text-justify: inter-ideograph;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/* Sections
   ========================================================================== */
body {
  margin: 0; }

*, *::before, *::after {
  box-sizing: border-box; }

main {
  display: block; }

h1, h2, h3,
h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.4; }

/* Grouping content
   ========================================================================== */
address {
  font-style: normal; }

figure {
  margin: 0; }

hr {
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden; }

pre {
  font-family: monospace, monospace;
  font-size: inherit; }

/* Links
   ========================================================================== */
a {
  color: currentColor;
  text-decoration: none;
  outline: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/* Text-level semantics
   ========================================================================== */
p {
  margin: 0; }

abbr[title] {
  border-bottom: 0;
  text-decoration: underline dotted; }

b, strong {
  font-weight: 700; }

em {
  font-weight: 700;
  font-style: normal; }

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: inherit; }

small {
  font-size: calc(1.5em / 1.7); }

sup, sub {
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Lists
   ========================================================================== */
dl, ol, ul,
dt, dd, li {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

dt {
  font-weight: 700; }

/* Tables
   ========================================================================== */
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; }

td, th {
  padding: 0; }

/* Embedded content
   ========================================================================== */
img, svg,
audio, video,
canvas, iframe {
  vertical-align: middle; }

img {
  max-width: 100%;
  border: 0; }
  img[src$=".svg"] {
    height: 100%; }

svg:not(:root) {
  overflow: hidden; }

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: dotted 1px ButtonText; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
  box-sizing: border-box;
  display: table; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

/* Interactive
   ========================================================================== */
details {
  display: block; }

summary {
  display: list-item; }

/* Misc
   ========================================================================== */
template,
[hidden] {
  display: none; }

/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
html {
  color: #333;
  background-color: #f7f7f7; }

body {
  line-height: 1.5; }
  @media screen and (max-width: 740px) {
    body {
      min-width: 320px;
      font-size: 1.5rem; } }
  @media screen and (min-width: 741px) {
    body {
      font-size: 1.6rem; } }

::-moz-selection {
  text-shadow: none;
  background-color: #b3d4fc; }

::selection {
  text-shadow: none;
  background-color: #b3d4fc; }

a {
  color: #888;
  transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1); }
  a.is-actv {
    color: #016666; }
  .mn-foot a {
    color: rgba(255, 255, 255, 0.5); }
  .mn-art a {
    background-image: linear-gradient(transparent 70%, #d0e0ff 0%); }
    .mn-art a.bt, .mn-art a.bt2 {
      background-image: none; }
  .mn-cnt_inr a {
    color: #4F59AD;
    font-weight: 700;
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .mn-cnt_inr a[target="_blank"]::after, .mn-cnt_inr a[href$=".pdf"]::after, .mn-cnt_inr a.pdf::after, .mn-cnt_inr a[href$=".docx"]::after {
      padding: 0 .4em 0 .2em;
      font-family: icomoon;
      font-size: calc(1.5em / 1.3);
      font-weight: 500;
      line-height: 1;
      position: relative;
      top: 0.04em; }
    .mn-cnt_inr a[target="_blank"]::after {
      content: '\e900'; }
    .mn-cnt_inr a[href$=".jpg"]::after, .mn-cnt_inr a[href$=".gif"]::after, .mn-cnt_inr a[href$=".png"]::after {
      content: none; }
    .mn-cnt_inr a[href$=".pdf"]::after, .mn-cnt_inr a.pdf::after {
      content: '\e902'; }
    .mn-cnt_inr a[href$=".docx"]::after {
      content: '\e904'; }
    .tmp9 .mn-cnt_inr a[target="_blank"]::after {
      content: '\e901'; }
    .tmp9 .mn-cnt_inr a[href$=".pdf"]::after {
      content: '\e903'; }
    .tmp9 .mn-cnt_inr a[href$=".docx"]::after {
      content: '\e905'; }
    .mn-cnt_inr a.noicn {
      background-image: none; }
      .mn-cnt_inr a.noicn::before, .mn-cnt_inr a.noicn::after {
        display: none; }
  @media screen and (min-width: 741px) {
    a:hover {
      color: #016666; }
      .mn-foot a:hover {
        color: #e12278; }
      .mn-cnt_inr a:hover {
        color: #3E8DFF;
        opacity: 0.4; } }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.ttf?sjb745") format("truetype"), url("../fonts/icomoon.woff?sjb745") format("woff"), url("../fonts/icomoon.svg?sjb745#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  [class^="icn-"][target="_blank"]::after, [class*=" icn-"][target="_blank"]::after {
    display: none; }

.icn-usr-sld:before {
  content: "\e90f"; }

.icn-usr:before {
  content: "\e910"; }

.icn-home-sld:before {
  content: "\e90d"; }

.icn-home:before {
  content: "\e90e"; }

.icn-splt:before {
  content: "\e90c"; }

.icn-txt-as:before {
  content: "\e90a"; }

.icn-txt-qs:before {
  content: "\e90b"; }

.icn-lnk-arw:before {
  content: "\e909"; }

.icn-lnk-m:before {
  content: "\e908"; }

.icn-lnk:before {
  content: "\e906"; }

.icn-lst-arw:before {
  content: "\e907"; }

.icn-blnk-sld:before {
  content: "\e900"; }

.icn-blnk:before {
  content: "\e901"; }

.icn-pdf-sld:before {
  content: "\e902"; }

.icn-pdf:before {
  content: "\e903"; }

.icn-wrd-sld:before {
  content: "\e904"; }

.icn-wrd:before {
  content: "\e905"; }

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */
/* General styles
   ========================================================================== */
.id-num {
  padding: 0.5em;
  color: #fff;
  font-size: 0.8125em;
  font-weight: 700;
  line-height: 1;
  background-color: #e12278;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000; }

.logo {
  width: 100%;
  line-height: 1;
  display: block; }
  .logo.-mh-rsj-logo {
    max-width: calc(20.2em / 1.7);
    margin-right: auto;
    flex-shrink: 0; }
    .pg1 .logo.-mh-rsj-logo, .pg2 .logo.-mh-rsj-logo {
      max-width: calc(34em / 1.7);
      flex-shrink: 1; }
    .fxd-nav .logo.-mh-rsj-logo {
      max-width: calc(32em / 1.7);
      margin: 0 auto; }
    .ua-ie-lgcy .logo.-mh-rsj-logo {
      height: 2em; }
    .pg1.ua-ie-lgcy .logo.-mh-rsj-logo, .pg2.ua-ie-lgcy .logo.-mh-rsj-logo {
      height: 3.41176em; }
  .logo.-mf-rsj-logo {
    max-width: calc(40em / 1.7); }
    .ua-ie-lgcy .logo.-mf-rsj-logo {
      height: 4em; }
  .logo.-rbgk-logo {
    max-width: calc(10.8em / 1.7);
    margin-left: calc(3em / 1.7);
    flex-shrink: 0; }
    .ua-ie-lgcy .logo.-rbgk-logo {
      height: 2.05882em; }
  .logo.-rsj-403-logo {
    max-width: 31.25em; }
  .logo_lnk, .logo_wrp {
    width: 100%;
    height: 100%;
    display: block; }
    .-mh-rsj-logo .logo_lnk,
    .-rsj-403-logo .logo_lnk, .-mh-rsj-logo .logo_wrp,
    .-rsj-403-logo .logo_wrp {
      color: #016666 !important; }
    .-mf-rsj-logo .logo_lnk, .-mf-rsj-logo .logo_wrp {
      color: #fff !important; }
    .-rbgk-logo .logo_lnk, .-rbgk-logo .logo_wrp {
      color: #142f85 !important; }
  @media screen and (max-width: 740px) {
    .logo.-mh-rsj-logo {
      overflow: hidden; }
      .pg1 .logo.-mh-rsj-logo, .pg2 .logo.-mh-rsj-logo {
        width: calc(9.5em / 1.5);
        font-size: 1.7rem; }
      .fxd-nav-otr .logo.-mh-rsj-logo {
        width: auto;
        max-width: calc(28em / 1.8); }
    .logo.-mf-rsj-logo {
      margin: 0 auto; }
    .logo.-rbgk-logo {
      max-width: calc(8em / 1.5);
      margin-left: 12px; }
    .logo.-rsj-403-logo {
      width: 93.26425%;
      margin: 0 auto; }
    .pg1 .-mh-rsj-logo .logo_lnk, .pg2 .-mh-rsj-logo .logo_lnk {
      width: calc(23em / 1.5); }
    .fxd-nav-otr .-mh-rsj-logo .logo_lnk {
      width: auto; } }
  @media screen and (max-width: 425px) {
    .logo.-mh-rsj-logo {
      width: calc(7.3em / 1.5); }
    .logo.-rsj-403-logo {
      max-width: 22.85714em; }
    .-mh-rsj-logo .logo_lnk {
      width: calc(17.8em / 1.5); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .logo.-mf-rsj-logo {
      margin: 0 auto; } }

.btn {
  width: 100%;
  line-height: 1.5;
  display: block; }
  .btn.-sns-btn {
    width: calc(1.7em / 1.3);
    height: calc(1.7em / 1.3);
    line-height: 0; }
  .btn.-login-btn {
    width: 7.5em;
    height: 30px;
    color: #fff;
    font-size: .8em;
    font-weight: 700;
    line-height: 1;
    border-radius: 100em;
    background-color: #016666;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .btn.-nav-btn {
    width: 7.5em;
    height: 30px;
    color: #fff;
    font-size: .8em;
    font-weight: 700;
    line-height: 1;
    border-radius: 100em;
    background-color: #4F59AD;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .btn.-nav-btn::after {
      content: '';
      width: calc(2.8em / 1.3);
      height: calc(2.8em / 1.3);
      margin-left: calc(0.8em / 1.3);
      background-image: url(../img/img_icn_nav.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: calc(2.8em / 1.3) calc(2.8em / 1.3);
      display: block; }
    .btn.-nav-btn.-nav-cls-btn {
      width: calc(18em / 1.5);
      height: calc(3.5em / 1.5);
      margin-top: calc(3em / 1.5);
      font-size: calc(1.5em / 1.7); }
      .btn.-nav-btn.-nav-cls-btn::after {
        content: '';
        width: calc(1.8em / 1.3);
        height: calc(1.8em / 1.3);
        background-image: url(../img/img_icn_nav_cls.svg);
        background-size: calc(1.8em / 1.3) calc(1.8em / 1.3);
        transform: rotate(45deg); }
    .-prm-inr .btn.-nav-btn {
      margin-left: calc(3em / 1.3); }
  .btn.-dtl-btn {
    padding: calc(0.4em / 1.5) calc(1.5em / 1.5);
    font-size: calc(1.5em / 1.7);
    font-weight: 700;
    border-radius: 100em;
    background-color: #016666;
    overflow: hidden; }
    .cnt-bnr-lst .btn.-dtl-btn {
      max-width: calc(20em / 1.5);
      margin-top: calc(2em / 1.5);
      background-color: #fff; }
  .btn.-more-btn {
    width: auto;
    height: calc(2.6em / 1.3);
    padding: 0 calc(1.8em / 1.5);
    margin: 0 calc(2em / 1.3) 0 auto;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    border-radius: 100em;
    background-color: #4F59AD;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .btn.-acrd-btn {
    width: calc(14em / 1.5);
    height: calc(2.4em / 1.5);
    margin: 0 0 calc(2em / 1.5) auto;
    color: #fff;
    font-size: calc(1.5em / 1.7);
    font-weight: 700;
    border-radius: 100em;
    background-color: #016666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .is-shw .btn.-acrd-btn::before {
      content: attr(data-tgl); }
  .btn.-gmap-btn {
    width: calc(11.4em / 1.3);
    border-radius: 100em;
    background-color: #fff;
    opacity: 0.5; }
  .btn.-jstage {
    height: 3.125em;
    max-width: 22.5em;
    margin-top: 2.5em;
    font-weight: 700;
    border-radius: 0.1875em;
    background-color: #016666;
    overflow: hidden; }
  .btn_lnk, .btn_wrp {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .-tw-btn .btn_lnk {
    color: #000 !important;
    background: none; }
  .-fb-btn .btn_lnk {
    color: #1877f2 !important; }
  .gen-foot .-tw-btn .btn_lnk, .gen-foot
  .-fb-btn .btn_lnk {
    color: #fff !important; }
  .-dtl-btn .btn_lnk {
    color: #fff !important;
    justify-content: space-between; }
    .cnt-bnr-lst .-dtl-btn .btn_lnk {
      color: #044a63 !important;
      justify-content: center; }
    .-dtl-btn .btn_lnk::after {
      content: '\e909';
      margin-left: 0.5em;
      font-family: icomoon;
      font-size: calc(1.65em / 1.4);
      font-weight: 500;
      line-height: 1; }
  .-gmap-btn .btn_lnk {
    padding: calc(0.1em / 1.3) 0;
    color: #022121 !important; }
  .-jstage .btn_lnk {
    color: #fff !important; }
    .-jstage .btn_lnk > i {
      margin-left: 0.4em; }
  .-more-btn .btn_wrp,
  .-acrd-btn .btn_wrp {
    width: auto;
    height: auto;
    display: inline; }
    .is-shw .-more-btn .btn_wrp, .is-shw
    .-acrd-btn .btn_wrp {
      display: none; }
  .btn_icn {
    width: 100%;
    display: block;
    flex-shrink: 0; }
    .btn_icn.-arw-icn {
      width: calc(1.6em / 1.5);
      margin-left: calc(0.8em / 1.5); }
      .ua-ie-lgcy .btn_icn.-arw-icn {
        height: 1.06667em; }
    .btn_icn.-gmap-icn {
      width: calc(1.5em / 1.3);
      margin-right: calc(0.4em / 1.3); }
      .ua-ie-lgcy .btn_icn.-gmap-icn {
        height: 1.15385em; }
  .btn_tgl {
    width: calc(1.3em / 1.5);
    height: calc(0.3em / 1.5);
    line-height: 1;
    background-color: currentColor; }
    .btn_tgl::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: inherit;
      display: block;
      transform: rotate(-90deg);
      transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1); }
      .is-shw .btn_tgl::before {
        transform: rotate(0); }
    .-acrd-btn .btn_tgl {
      margin: 0 0 0 calc(1em / 1.5); }
  @media screen and (max-width: 740px) {
    .-prm-inr .btn.-nav-btn {
      display: none; }
    .-scnd-inr .btn.-nav-btn {
      height: 30px;
      margin: 6px 0 6px 6px; }
    .btn.-more-btn {
      width: calc(3.1em / 1.5);
      height: calc(3.1em / 1.5);
      padding: 0;
      border-radius: 50%; }
    .btn.-jstage {
      margin-right: auto;
      margin-left: auto; }
    .-more-btn .btn_wrp {
      display: none; } }
  @media screen and (min-width: 741px) {
    .-scnd-inr .btn.-nav-btn {
      display: none; }
    .is-shw .btn.-more-btn::after {
      content: attr(data-tgl); }
    .btn.-gmap-btn {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .btn.-gmap-btn:hover {
        opacity: 1; }
    .btn_tgl {
      margin-right: calc(1em / 1.5); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .-prm-inr .btn.-nav-btn {
      display: none; }
    .-scnd-inr .btn.-nav-btn {
      height: 30px;
      margin: 8px 0 8px 10px;
      display: flex; }
    .btn.-jstage {
      margin-right: auto;
      margin-left: auto; } }

h2 + .btn.-acrd-btn {
  margin-top: -1em; }

h3 + .btn.-acrd-btn {
  margin-top: -.7em; }

.fxd-bnt-otr {
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms cubic-bezier(0.23, 1, 0.32, 1); }
  .is-shw .fxd-bnt-otr {
    opacity: 1;
    visibility: visible;
    transition: opacity 600ms 600ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms 600ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms 600ms cubic-bezier(0.23, 1, 0.32, 1); }

.more {
  margin: 1em auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /*
	&.right {
		justify-content: flex-end;
	}
*/ }
  .more.center {
    justify-content: center; }
  .more.left {
    justify-content: flex-start; }
  .rbgk-tm-line .more {
    margin-block: 0;
    margin-inline: 0; }
  .more a {
    padding: calc(0.4em / 1.5) 1em;
    color: #fff !important;
    font-size: calc(1.5em / 1.7);
    font-weight: 700;
    line-height: 1.5;
    border-radius: 100em;
    background: #4F59AD;
    display: flex;
    align-items: baseline;
    justify-content: center; }
    .more a::after {
      content: '\e909';
      margin-left: calc(0.8em / 1.6);
      font-family: icomoon;
      font-size: calc(1.6em / 1.5);
      font-weight: 500;
      line-height: 1;
      position: relative;
      top: calc(0.15em / 1.6); }
    .rbgk-tm-line .more a::after {
      content: '\e909';
      top: calc(0.15em / 1.6); }
  .more.grn a {
    background-color: #016666; }

.bt, .bt2, .bt3 {
  margin: 1em auto;
  color: #fff !important;
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  line-height: 1.5;
  background: #4F59AD;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .bt.grn, .bt2.grn, .bt3.grn {
    background-color: #016666; }
  .bt.blk, .bt2.blk, .bt3.blk {
    background-color: #000; }
  .bt.nmg, .bt2.nmg, .bt3.nmg {
    margin: 0 auto; }
  .bt a, .bt2 a, .bt3 a {
    background: none;
    width: 100%;
    color: inherit !important;
    opacity: 1 !important;
    display: flex;
    align-items: center;
    justify-content: center; }
    .bt a::before, .bt2 a::before, .bt3 a::before {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
  @media screen and (min-width: 741px) {
    .bt, .bt2, .bt3 {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .bt:hover, .bt2:hover, .bt3:hover {
        opacity: 0.4; } }

.bt {
  padding: .4em 1em;
  max-width: 15em;
  border-radius: 100em; }

.bt2 {
  padding: .6em 1em;
  max-width: 20em;
  border-radius: .3em;
  overflow: hidden; }
  .tmp9 .bt2,
  .tmp28 .bt2 {
    margin: 0 auto;
    padding: 0;
    border-radius: .2em; }
  .tmp9 .bt2 a,
  .tmp28 .bt2 a {
    align-items: stretch;
    justify-content: flex-start; }
    .tmp9 .bt2 a::after,
    .tmp28 .bt2 a::after {
      width: calc(4.5em / 2);
      padding: 0;
      font-size: calc(2em / 1.5);
      background-color: #199696;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      order: -5;
      top: 0; }
    .tmp9 .bt2 a > span,
    .tmp28 .bt2 a > span {
      width: 100%;
      padding: calc(1.15em / 1.5) 1em;
      text-align: center; }
  .tmp28 .bt2 a > span {
    padding: calc(0.8em / 1.5) 1em; }

.bt3 {
  padding: .6em 1em;
  max-width: 35em;
  border-radius: .3em;
  overflow: hidden; }

td.bt {
  border-radius: 0;
  background-color: transparent !important;
  opacity: 1 !important;
  display: table-cell; }
  td.bt.grn a {
    margin: 0.5em auto;
    padding: calc(0.6em / 1.5) 1em;
    border-radius: calc(0.3em / 1.5);
    background-color: #016666; }
    td.bt.grn a::before {
      display: none; }

td a.bt {
  border-radius: calc(0.3em / 1.5); }

@media screen and (min-width: 741px) {
  td.bt.grn a {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    td.bt.grn a:hover {
      opacity: 0.4 !important; } }

.tmp9 .btn-lst {
  margin-top: calc(3.4em / 1.7); }

.tmp28 .btn-lst {
  margin-top: calc(2em / 1.7); }

.btn-lst_itm {
  padding-left: 0 !important; }
  .btn-lst_itm:nth-child(n+2) {
    margin-top: calc(0.6em / 1.7) !important; }
  .btn-lst_itm::before {
    display: none; }

.pagenation {
  color: #ddd;
  margin-top: 2em;
  text-align: center; }
  .pagenation span > span {
    color: #333; }
  .pagenation span > span, .pagenation span > a {
    display: inline-block;
    padding: .2em .5em;
    margin: .2em;
    background: #ededed; }

.advpub-btn {
  margin: 2em auto 0;
  font-size: 1.1em; }
  .advpub-cpy + .advpub-btn {
    margin-top: 1em; }

.icn {
  line-height: 0;
  display: block;
  flex-shrink: 0; }
  .icn.-gen-s-icn {
    width: calc(1.1em / 1.3);
    margin-right: calc(0.8em / 1.3);
    align-self: flex-start;
    position: relative;
    top: calc(0.3em / 1.3); }
    .lng-lst .icn.-gen-s-icn {
      margin: 0;
      color: #888;
      position: absolute;
      top: 50%;
      right: calc(100% + 1em / 1.3);
      transform: translate(0, -50%) rotate(105deg); }
      .gen-foot .lng-lst .icn.-gen-s-icn {
        color: #fff; }
    .cnt-top-lst_dt .icn.-gen-s-icn {
      margin: 0;
      position: absolute;
      top: 50%;
      right: calc(100% + 0.5em / 1.3);
      transform: translate(0, -50%) rotate(105deg); }
    .cnt-lst_itm.-lv01 .icn.-gen-s-icn {
      width: calc(1.1em / 1.5);
      margin-right: calc(0.8em / 1.5);
      top: calc(0.5em / 1.5); }
    .addr-tel .icn.-gen-s-icn {
      margin: 0;
      position: absolute;
      top: calc(50% - 0.1em / 1.3);
      right: calc(100% + 1em / 1.3);
      transform: translate(0, -50%) rotate(105deg); }
    .ua-ie-lgcy .icn.-gen-s-icn {
      height: 0.84615em; }
  .icn.-arw-icn {
    width: calc(1.6em / 1.3);
    margin-left: calc(0.8em / 1.3); }
    .ua-ie-lgcy .icn.-arw-icn {
      height: 1.23077em; }
  .icn.-lst-arw-icn {
    width: calc(0.7em / 1.3); }
    .bc-lst .icn.-lst-arw-icn {
      margin: 0 2em;
      position: relative;
      top: calc(-0.1em / 1.3); }
  @media screen and (max-width: 740px) {
    .bc-lst .icn.-lst-arw-icn {
      margin: 0 1em; } }

.icn-home-sld {
  font-size: 1.4em;
  background-image: none !important; }
  .icn-home-sld, .icn-home-sld:hover {
    color: #016666 !important; }

.lst-otr {
  margin-left: calc(3em / 1.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end; }
  .lst-otr > *:nth-child(n+2) {
    margin-left: calc(3em / 1.3); }
  .gen-foot .lst-otr {
    width: 100%;
    margin-left: 0; }
  @media screen and (max-width: 740px) {
    .gen-foot .lst-otr {
      flex-direction: column; }
      .gen-foot .lst-otr > *:nth-child(n+2) {
        margin: calc(1.5em / 1.3) 0 0; } }
  @media screen and (min-width: 741px) {
    .gen-foot .lst-otr {
      margin-bottom: calc(2.5em / 1.7); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .gen-foot .lst-otr {
      margin-bottom: 0;
      flex-direction: column; }
      .gen-foot .lst-otr > *:nth-child(n+2) {
        margin: calc(1.5em / 1.3) 0 0; } }

.lng-lst {
  font-size: calc(1.3em / 1.7);
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center; }
  .lng-lst_itm {
    display: flex;
    align-items: center; }
    .lng-lst_itm:nth-child(n+2)::before {
      content: '\e90c';
      margin: 0 0.6em;
      color: #888 !important;
      font-family: icomoon;
      font-weight: 500;
      line-height: 1;
      display: block; }
  .lng-lst_lnk.is-actv {
    color: #016666; }
    .gen-foot .lng-lst_lnk.is-actv {
      color: #e12278; }
  @media screen and (max-width: 740px) {
    .lng-lst {
      font-size: 0.93333em; }
      .mn-head .lng-lst_lnk::before {
        content: attr(data-lng); }
      .mn-head .lng-lst_txt-wrp::before {
        content: attr(data-lng); }
      .mn-head .lng-lst_txt-wrp > span {
        display: none; } }

.sns-lst {
  font-size: calc(1.3em / 1.7);
  line-height: 1;
  display: flex;
  align-items: center; }
  .mn-art-head .sns-lst {
    margin-top: 0.61538em; }
  .sns-lst_itm {
    margin-top: 0 !important;
    padding: 0 !important;
    flex-shrink: 0; }
    .sns-lst_itm::before {
      display: none; }
    .sns-lst_itm:nth-child(2) {
      width: 108px; }
    .sns-lst_itm:nth-child(n+2) {
      margin-left: 1em; }

.sub-lnk-lst {
  font-size: calc(1.3em / 1.7);
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: flex-start; }
  .sub-lnk-lst_itm:nth-child(n+2) {
    margin-left: calc(3em / 1.3); }
  .sub-lnk-lst_lnk {
    display: flex; }
  @media screen and (max-width: 740px) {
    .mn-head .sub-lnk-lst {
      display: none; }
    .gen-foot .sub-lnk-lst {
      margin: calc(1.5em / 1.3) 0 0; } }
  @media screen and (max-width: 425px) {
    .gen-foot .sub-lnk-lst {
      display: none; } }
  @media screen and (min-width: 741px) {
    .gen-foot .sub-lnk-lst {
      margin-left: auto;
      order: 5; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .mn-head .sub-lnk-lst {
      display: none; }
    .gen-foot .sub-lnk-lst {
      margin: calc(1.5em / 1.3) 0 0;
      order: 0; } }

.fb-share-button a::after {
  display: none !important; }

.gmap {
  width: 100%; }
  @media screen and (max-width: 740px) {
    .gmap {
      height: 60vw;
      min-height: 260px; } }
  @media screen and (min-width: 741px) {
    .gmap {
      height: 450px; } }

#ajaxSearch_form {
  width: 100%;
  margin-right: auto; }
  .fxd-nav #ajaxSearch_form {
    margin-top: calc(2em / 1.7); }
  #ajaxSearch_form label {
    display: block; }
    #ajaxSearch_form label:nth-child(3) {
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0); }

#ajaxSearch_input {
  height: 30px;
  width: 100%;
  padding: 0 1em 0 calc(5.2em / 1.5);
  color: #888;
  line-height: 1;
  border: 0;
  border-radius: 100em;
  background-color: #ededed;
  background-image: url(../img/img_icn_srch.svg);
  background-repeat: no-repeat;
  background-position: calc(2em / 1.5) center;
  background-size: calc(2em / 1.5) calc(2em / 1.5);
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none; }

@media screen and (max-width: 740px) {
  #ajaxSearch_input {
    padding-left: calc(1em * 2 + 0.7em);
    font-size: 1.6rem;
    background-position: 1em center;
    background-size: 1em 1em; } }

@media screen and (min-width: 741px) {
  .mn-nav #ajaxSearch_form {
    max-width: 30em; }
  #ajaxSearch_input {
    font-size: calc(1.5em / 1.7); } }

@media screen and (min-width: 741px) and (max-width: 860px) {
  .mn-nav #ajaxSearch_form {
    max-width: none; }
  #ajaxSearch_input {
    height: 30px; } }

.ajaxSearch_paging {
  margin-top: 1.76471em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.ajaxSearch_paging-ttl {
  margin-bottom: 0.31579em;
  color: #016666;
  font-size: 1.11765em;
  font-weight: 700;
  line-height: 1.4; }

.ajaxSearch_paging-lst {
  margin: -1px;
  color: #fff;
  font-size: 0.82353em;
  font-weight: 700;
  line-height: 1;
  display: flex;
  flex-wrap: wrap; }
  .ajaxSearch_paging-lst > * {
    width: 2em;
    height: 2em;
    margin: 1px;
    background-color: #016666;
    display: flex;
    align-items: center;
    justify-content: center; }

.ajaxSearch_currentPage {
  background-color: #ccc;
  opacity: 1 !important; }

.ajaxSearch_result {
  margin: 1.76471em 0 0;
  padding: 0;
  border: 0; }
  .ajaxSearch_result:nth-child(n+4) {
    margin-top: 1em;
    padding-top: 1em;
    border-top: solid 2px rgba(51, 51, 51, 0.2); }

.ajaxSearch_resultLink {
  color: #016666 !important;
  font-size: 1.23529em;
  font-weight: 700;
  line-height: 1.4; }

.ajaxSearch_resultExtract {
  color: #555;
  font-size: 0.88235em;
  word-wrap: break-word; }
  .ajaxSearch_resultExtract p {
    margin-top: 0.5em; }

.ajaxSearch_highlight {
  color: #e12278;
  font-weight: 700; }

@media screen and (min-width: 741px) {
  .ajaxSearch_paging-lst > * {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .ajaxSearch_paging-lst > *:hover {
      opacity: 0.4; } }

.doc-srch {
  width: 100%;
  color: #333; }
  .srch-otr .doc-srch {
    margin: 0 auto; }
  .fxd-nav .doc-srch {
    max-width: none;
    margin-top: 1.17647em; }
  .doc-srch_bdy {
    width: 100%;
    display: block; }
  .doc-srch_flx {
    display: flex;
    align-items: center; }
    .doc-srch_flx.-rdo {
      padding: 0 1em; }
      .fxd-nav .doc-srch_flx.-rdo {
        position: static; }
    .doc-srch_flx > *:nth-child(2) {
      margin-left: 1em; }
  .doc-srch_kw {
    height: 30px;
    width: 100%;
    padding: 0 1em 0 calc(5.2em / 1.5);
    color: #888;
    line-height: 1;
    border: 0;
    border-radius: 100em;
    background-color: #ededed;
    background-image: url(../img/img_icn_srch.svg);
    background-repeat: no-repeat;
    background-position: calc(2em / 1.5) center;
    background-size: calc(2em / 1.5) calc(2em / 1.5);
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none; }
  .doc-srch_rdo-lbl {
    margin: 0;
    line-height: 1;
    display: block; }
  @media screen and (max-width: 740px) {
    .doc-srch {
      position: relative; }
      .doc-srch_flx.-rdo {
        margin-top: 0.9em;
        position: absolute;
        top: 100%;
        left: 0; }
      .doc-srch_kw {
        padding-left: calc(1em * 2 + 0.7em);
        font-size: 1.6rem;
        background-position: 1em center;
        background-size: 1em 1em; } }
  @media screen and (max-width: 425px) {
    .mn-nav .doc-srch_flx.-rdo {
      margin-top: 0.8em;
      justify-content: center;
      position: static;
      display: none; } }
  @media screen and (min-width: 741px) {
    .doc-srch {
      display: flex;
      align-items: center; }
      .fxd-nav .doc-srch {
        display: block; }
      .doc-srch_bdy {
        max-width: 30em; }
        .fxd-nav .doc-srch_bdy {
          max-width: none; }
      .fxd-nav .doc-srch_flx.-rdo {
        margin-top: 0.8em; }
      .doc-srch_kw {
        font-size: calc(1.5em / 1.7); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .doc-srch {
      display: block;
      position: relative; }
      .doc-srch_bdy {
        max-width: none; }
      .doc-srch_flx.-rdo {
        margin-top: 0.9em;
        position: absolute;
        top: 100%;
        left: 0; }
      .doc-srch_kw {
        height: 30px; } }

.rslt-txt {
  margin-top: 1.5em !important;
  line-height: 1.5; }

.prev_next {
  margin-bottom: 3em;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .prev_next span a {
    color: #fff;
    background-color: #016666; }
  .prev_next > span {
    margin-bottom: 5px; }
    .prev_next > span:nth-child(n+2) {
      margin-left: 5px; }
    .prev_next > span > * {
      min-width: 3em;
      height: 2.7em;
      padding: 0.5em !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      background-color: #ccc; }
  @media screen and (min-width: 741px) {
    .prev_next span a {
      transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
      .prev_next span a:hover {
        color: #fff;
        opacity: 0.4; } }

.srch-otr .doc-srch, .cnt-lst {
  max-width: 1200px;
  margin: 0 auto; }

.mn-head_inr, .mn-nav_inr.-scnd-inr {
  max-width: 1250px;
  margin: 0 auto; }

.foot-nav .cnt-lst, .spns_inr, .gen-foot_inr, .mn-cnt_inr, .bc-lst-otr {
  max-width: 1320px;
  margin: 0 auto; }

@media screen and (max-width: 740px) {
  .foot-nav .cnt-lst, .spns_inr, .gen-foot_inr, .mn-cnt_inr, .bc-lst-otr {
    padding-right: 20px;
    padding-left: 20px; }
  .mn-head_inr, .mn-nav_inr.-scnd-inr {
    padding-right: 12px;
    padding-left: 12px; } }

@media screen and (min-width: 741px) {
  .foot-nav .cnt-lst, .spns_inr, .gen-foot_inr, .mn-cnt_inr, .bc-lst-otr {
    padding-right: 60px;
    padding-left: 60px; }
  .mn-head_inr, .mn-nav_inr.-scnd-inr {
    padding-right: 25px;
    padding-left: 25px; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .foot-nav .cnt-lst, .spns_inr, .gen-foot_inr, .mn-cnt_inr, .bc-lst-otr {
    padding-right: 40px;
    padding-left: 40px; } }

/* Main header styles
   ========================================================================== */
.mn-head {
  border-top: solid 3px #016666;
  background-color: #fff; }
  .mn-head_inr {
    padding-top: calc(1em / 1.7);
    padding-bottom: calc(1em / 1.7);
    display: flex;
    align-items: center; }
  @media screen and (max-width: 740px) {
    .mn-head_inr {
      padding: 8px 12px; } }

/* Main navigation styles
   ========================================================================== */
.mn-nav {
  line-height: 1.5; }
  .mn-nav_inr {
    display: flex;
    align-items: center; }
    .mn-nav_inr.-prm-inr {
      padding: calc(1em / 1.7) 0;
      background-color: #f6f4ee;
      justify-content: center; }
    .mn-nav_inr.-scnd-inr {
      padding-top: calc(2em / 1.7);
      padding-bottom: calc(2em / 1.7); }
  @media screen and (max-width: 740px) {
    .mn-nav_inr.-prm-inr {
      padding: 0;
      display: none; }
    .mn-nav_inr.-scnd-inr {
      padding-top: 2px;
      padding-bottom: 6px;
      flex-wrap: wrap; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .mn-nav {
      font-size: calc(1.5em / 1.7); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .mn-nav_inr.-scnd-inr {
      padding-top: 16px;
      padding-bottom: 8px;
      flex-wrap: wrap; } }

.lgn-otr {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media screen and (max-width: 740px) {
    .lgn-otr {
      width: 100%; } }
  @media screen and (min-width: 741px) {
    .lgn-otr {
      margin-left: 20px; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .lgn-otr {
      width: 100%;
      margin-left: 0; } }

.usr-name {
  color: #555;
  font-size: 0.9375em;
  font-weight: 700;
  display: flex;
  align-items: baseline; }
  .usr-name::before {
    content: '\e90f';
    margin-right: 0.2em;
    color: #016666;
    font-family: icomoon;
    font-size: 1.13333em;
    font-weight: 500;
    line-height: 1;
    position: relative;
    top: 0.1em; }
  .usr-name_lnk {
    color: #555 !important; }
  @media screen and (max-width: 740px) {
    .usr-name {
      margin: 6px auto 6px 0;
      font-size: 0.86667em; } }
  @media screen and (min-width: 741px) {
    .usr-name_lnk:hover {
      color: #016666 !important; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .usr-name {
      margin: 8px auto 8px 0; } }

/* Fixed navigation styles
   ======================================== */
.fxd-nav-otr {
  width: 100%;
  height: 100%;
  padding: calc(4em / 1.7) 0 calc(6em / 1.7);
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms cubic-bezier(0.23, 1, 0.32, 1); }
  .fxd-nav-otr.is-shw {
    opacity: 1;
    visibility: visible; }
  .fxd-nav-otr > * {
    position: relative;
    z-index: 10; }

.fxd-nav {
  width: 90%;
  padding-top: calc(3em / 1.7);
  border-radius: 0.58824em;
  background-color: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.75);
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms cubic-bezier(0.23, 1, 0.32, 1); }
  .fxd-nav::-webkit-scrollbar {
    display: none; }
  .fxd-nav::after {
    content: '';
    width: 100%;
    padding-top: calc(6em / 1.7);
    display: block; }
  .is-shw .fxd-nav {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity 600ms 300ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (max-width: 740px) {
    .fxd-nav {
      padding-right: 20px;
      padding-left: 20px; } }
  @media screen and (min-width: 741px) {
    .fxd-nav {
      padding-right: 60px;
      padding-left: 60px; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .fxd-nav {
      padding-right: 40px;
      padding-left: 40px; } }

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5; }

/* Navigation list styles
   ======================================== */
.nav-lst-otr {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .nav-lst-otr::-webkit-scrollbar {
    display: none; }
  @media screen and (max-width: 740px) {
    .nav-lst-otr {
      padding: calc(0.8em / 1.5) 0; } }

.nav-lst {
  font-weight: 700;
  display: flex;
  align-items: center; }
  .nav-lst_itm {
    display: flex;
    flex-shrink: 0;
    align-items: center; }
    .nav-lst_itm:nth-last-child(n+2)::after {
      content: '';
      width: calc(1.3em / 1.7);
      height: calc(1.3em / 1.7);
      margin: 0 calc(3em / 1.7);
      background-image: url(../img/img_icn_splt.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: calc(1.3em / 1.7) calc(1.3em / 1.7);
      display: block;
      transform: rotate(15deg); }
  .nav-lst_lnk {
    color: #555; }
    .is-actv > .nav-lst_lnk {
      color: #016666; }
  @media screen and (max-width: 740px) {
    .nav-lst_itm:first-child {
      padding-left: 20px; }
    .nav-lst_itm:last-child {
      padding-right: 20px; }
    .nav-lst_itm:nth-last-child(n+2)::after {
      margin: 0 calc(1.5em / 1.5); } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .nav-lst_itm:nth-last-child(n+2)::after {
      margin: 0 calc(1.8em / 1.5); } }

/* Pickup list styles
   ======================================== */
.pu-lst {
  margin-left: calc(2.4em / 1.5);
  font-size: calc(1.5em / 1.7);
  font-weight: 700;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center; }
  .pu-lst_itm:nth-child(n+2) {
    margin-left: calc(2.4em / 1.5); }
  @media screen and (max-width: 740px) {
    .pu-lst {
      display: none; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .pu-lst {
      display: none; } }

/* Contents list styles
   ======================================== */
.cnt-lst {
  font-size: 0.9375em;
  font-weight: 700;
  line-height: 1.5; }
  .cnt-lst.-ch-lst {
    max-width: none;
    margin-right: -1.53846em;
    padding: 0 0 0 1.53846em;
    font-size: 0.86667em;
    display: flex;
    flex-wrap: wrap; }
  .fxd-nav .cnt-lst:not(.-ch-lst) {
    margin-top: 2em; }
  .-lv01 > .cnt-lst_lnk {
    color: #555;
    line-height: 1.4;
    display: inline-flex; }
    .foot-nav .-lv01 > .cnt-lst_lnk {
      color: #fff; }
  .is-actv > .cnt-lst_lnk {
    color: #016666; }
    .foot-nav .is-actv > .cnt-lst_lnk {
      color: #e12278; }
  .cnt-lst_itm.-lv01:nth-child(n+2) {
    margin-top: 1.66667em; }
  .-ch-lst .cnt-lst_itm {
    margin: 0.76923em 1.53846em 0 0; }
  @media screen and (min-width: 741px) {
    .foot-nav .cnt-lst.-ch-lst {
      margin: 0.92308em 0 0;
      display: block; }
    .foot-nav .cnt-lst {
      display: flex;
      flex-wrap: wrap; }
    .foot-nav .cnt-lst_itm.-lv01 {
      width: calc((100% - 20px * 4) / 5); }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(n+2) {
        margin: 0 0 0 20px; }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(n+6) {
        margin-top: 40px; }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(5n+6) {
        margin-left: 0; }
    .foot-nav .-ch-lst .cnt-lst_itm {
      margin: 0; }
      .foot-nav .-ch-lst .cnt-lst_itm:nth-child(n+2) {
        margin-top: 0.46154em; }
    .-lv01 > .cnt-lst_lnk:hover {
      color: #016666; }
      .foot-nav .-lv01 > .cnt-lst_lnk:hover {
        color: #e12278; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .foot-nav .cnt-lst_itm.-lv01 {
      width: calc((100% - 20px * 2) / 3); }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(n+2) {
        margin: 0 0 0 20px; }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(n+4) {
        margin-top: 40px; }
      .foot-nav .cnt-lst_itm.-lv01:nth-child(3n+4) {
        margin-left: 0; }
      .ua-ie .foot-nav .cnt-lst_itm.-lv01 {
        width: calc(33.33% - 20px); } }

/* Main footer styles
   ========================================================================== */
/* Sponsor styles
   ======================================== */
.spns {
  background-color: #e9e8e5; }
  .spns_inr {
    padding-top: 3em;
    padding-bottom: 3em; }

.spns-lst {
  color: #716e65; }
  .spns-lst_itm {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    .spns-lst_itm:nth-child(n+2) {
      margin-top: calc(4.5em / 1.7); }
  .spns-lst_trm {
    width: 100%;
    margin-bottom: calc(.3em);
    text-align: center;
    line-height: 1.4; }
  .spns-lst_lnk {
    border: solid 2px #d2cfc7;
    display: block;
    background-color: #fff;
    text-align: center; }
  @media screen and (max-width: 740px) {
    .spns-lst_dsc {
      width: calc((100% - 1em / 1.5) / 2);
      max-width: calc(23.5em / 1.5); }
      .spns-lst_dsc:nth-child(odd) {
        margin-left: calc(1em / 1.5); }
      .spns-lst_dsc:nth-child(n+4) {
        margin-top: calc(1em / 1.5); } }
  @media screen and (min-width: 741px) {
    .spns-lst_itm {
      margin: 0 calc(-1em / 1.7); }
    .spns-lst_dsc {
      width: calc(23.5em / 1.7);
      margin: calc(1em / 1.7); } }

/* Footer navigation styles
   ======================================== */
.foot-nav {
  padding-top: calc(8em / 1.7);
  padding-bottom: calc(8em / 1.7);
  background-color: #231f25;
  background-image: linear-gradient(-45deg, #160f1a 0%, #231f25 100%); }

/* General footer styles
   ======================================== */
.gen-foot {
  color: #fff;
  background-color: #013a3a;
  background-image: linear-gradient(-45deg, #011717 0%, #013a3a 100%); }
  .gen-foot_inr {
    padding-top: calc(8em / 1.7);
    padding-bottom: calc(12em / 1.7); }
  .gen-foot_flx {
    margin-top: calc(2.5em / 1.7);
    display: flex;
    flex-wrap: wrap; }
  @media screen and (max-width: 740px) {
    .gen-foot_flx {
      flex-direction: column;
      align-items: center; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .gen-foot_flx {
      flex-direction: column;
      align-items: center; } }

.addr-dtl {
  margin-top: calc(2.5em / 1.7); }
  .addr-dtl_trm {
    font-size: calc(1.5em / 1.7); }
  .addr-dtl_dsc {
    margin-top: calc(1em / 1.3);
    font-size: calc(1.3em / 1.7); }
  @media screen and (max-width: 740px) {
    .addr-dtl {
      text-align: center; }
      .addr-dtl_itm {
        display: flex;
        flex-direction: column;
        align-items: center; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .addr-dtl {
      text-align: center; }
      .addr-dtl_itm {
        display: flex;
        flex-direction: column;
        align-items: center; } }

.addr-tel {
  display: flex;
  align-items: center; }
  .addr-tel_itm {
    position: relative; }
    .addr-tel_itm:nth-child(n+2) {
      margin-left: calc(3.1em / 1.3); }
  .addr-tel_lnk {
    color: #fff !important; }
  @media screen and (max-width: 740px) {
    .addr-tel {
      justify-content: center; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .addr-tel {
      justify-content: center; } }

.cr-txt {
  font-size: 0.6875em;
  font-weight: 900;
  line-height: 1.5;
  text-align: left; }
  .gen-foot .cr-txt {
    font-weight: 500; }
  .p-403 .cr-txt {
    margin-top: 5.45455em; }
  .cr-txt_lnk {
    color: #fff !important; }
    .p-403 .cr-txt_lnk {
      color: #016666 !important; }
  @media screen and (max-width: 740px) {
    .cr-txt {
      margin-top: calc(2.5em / 1.1);
      text-align: center; } }
  @media screen and (min-width: 741px) {
    .cr-txt {
      order: 0; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .cr-txt {
      margin-top: calc(2.5em / 1.1);
      text-align: center;
      order: 5; } }

/* Main content styles
   ========================================================================== */
.mn-cnt {
  padding: 2em 0 4em; }
  .mn-cnt.-sub-cnt {
    padding-top: 1em; }
  @media screen and (min-width: 741px) {
    .-sub-cnt .mn-cnt_inr {
      display: flex; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .-sub-cnt .mn-cnt_inr {
      display: block; } }

.mn-art {
  width: 100%; }
  .mn-art * + section,
  .mn-art * + div.section {
    margin-top: calc(5em / 1.7); }
  .mn-art.mceContentBody {
    width: 100%; }
  .tmp30 .mn-art {
    width: 100%;
    margin-top: 2em; }
  .mn-art h2, .mn-art h3 {
    position: relative; }
    .mn-art h2::before, .mn-art h3::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
  .mn-art h2 {
    margin-bottom: calc(3.4em / 2.5);
    padding: calc(0.8em / 2.5) 0;
    font-size: calc(2.5em / 1.7);
    border-bottom: solid 3px #016666;
    border-top-left-radius: calc(0.5em / 2.5);
    overflow: hidden; }
    .mn-art h2::before {
      height: 100%;
      background-color: #016666;
      transform-origin: top right; }
  .mn-art * + h2 {
    margin-top: calc(5em / 2.5); }
  .mn-art h3 {
    margin-bottom: calc(1.7em / 2.1);
    padding: calc(0.3em / 2.1) 0 calc(0.3em / 2.1) calc(1.5em / 2.1);
    color: #016666;
    font-size: calc(2.1em / 1.7); }
    .mn-art h3::before {
      width: 0;
      height: 0;
      border: solid calc(0.75em / 2.1) #016666;
      border-right: solid calc(0.75em / 2.1) transparent;
      border-bottom: solid calc(0.75em / 2.1) transparent;
      border-top-left-radius: calc(0.5em / 2.1); }
  .mn-art h4 {
    margin-bottom: calc(1.7em / 1.9);
    color: #016666;
    font-size: calc(1.9em / 1.7); }
  .mn-art * + h3,
  .mn-art * + h4 {
    margin-top: calc(3.4em / 2.1); }
  .mn-art * + p {
    margin-top: 0.5em; }
    .ua-mac-os .mn-art * + p {
      text-align: left; }
  .mn-art .advpub-cpy {
    margin-top: 2em;
    color: #016666;
    font-weight: 700; }
  .mn-art ul, .mn-art ol {
    counter-reset: counter-ol; }
    .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li, .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li {
      border-radius: calc(0.3em / 1.7);
      position: relative; }
      .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li:nth-child(n+2), .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li:nth-child(n+2) {
        margin-top: calc(0.8em / 1.7); }
      .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li::before, .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li::before {
        position: absolute;
        top: 0;
        left: 0; }
      .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li[data-lst-styl]::before, .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li[data-lst-styl]::before {
        content: attr(data-lst-styl); }
    .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ul, .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ol, .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ul, .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ol {
      margin-top: calc(0.8em / 1.7);
      position: relative; }
    .mn-art ul.tri, .mn-art ol.tri {
      padding-left: 0; }
      .mn-art ul.tri::before, .mn-art ol.tri::before {
        display: none; }
  .mn-art * + ul,
  .mn-art * + ol {
    margin-top: 0.5em; }
  .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li {
    padding-left: 1em; }
    .mn-art ul:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li::before {
      content: '・'; }
  .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li {
    padding-left: calc(3.6em / 1.7);
    counter-increment: counter-ol; }
    .mn-art ol:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li::before {
      content: counter(counter-ol) "．";
      width: calc(3.6em / 1.7);
      text-align: right; }
  .mn-art .tri {
    padding-left: 1em;
    position: relative; }
    .mn-art .tri::before,
    .mn-art .tri li::before {
      content: '\e907' !important;
      color: #016666;
      font-family: icomoon;
      font-size: 0.6em;
      font-weight: 500;
      position: absolute;
      top: 0.55em !important;
      left: 0.3em !important; }
  @media screen and (max-width: 740px) {
    .mn-art h2 {
      padding-left: calc(4em / 2.2); }
      .mn-art h2::before {
        width: calc(3em / 2.2);
        transform: skew(-15deg, 0); } }
  @media screen and (min-width: 741px) {
    .mn-art {
      width: calc(100% - 300px);
      padding-right: 40px; }
      .mn-art h2 {
        padding-left: calc(7.5em / 2.5); }
        .mn-art h2::before {
          width: calc(6.5em / 2.5);
          transform: skew(-30deg, 0); }
      .mn-art .advpub-cpy {
        font-size: 1.1em; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .mn-art {
      width: calc(100% - 270px);
      padding-right: 20px; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .mn-art {
      width: 100%;
      padding-right: 0; } }

.mn-art-head {
  margin-bottom: 1.5em;
  color: #888;
  font-weight: 700; }
  .mn-art-head_ttl {
    color: #016666;
    font-size: 1.6em; }
  .mn-art-head_nts {
    margin-top: calc(0.5em / 1.5) !important;
    font-size: calc(1.5em / 1.7);
    line-height: 1.5;
    display: flex; }
    .mn-art-head_nts::before {
      content: '※'; }

.pagenate {
  margin-top: 4em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .pagenate_itm, .pagenate_prv, .pagenate_nxt {
    margin: 3px 2px; }
    .pagenate_itm > *, .pagenate_prv > *, .pagenate_nxt > * {
      height: 2.5em;
      min-width: 2.5em;
      padding: 0.5em;
      background-color: #ddd;
      display: flex;
      align-items: center;
      justify-content: center; }
  .pagenate a {
    color: #fff;
    background-color: #016666;
    background-image: none; }
  @media screen and (max-width: 740px) {
    .pagenate {
      font-size: 0.86667em; } }
  @media screen and (min-width: 741px) {
    .pagenate a:hover {
      color: #fff; } }

/* Breadcrumbs list styles
   ======================================== */
.bc-lst-otr {
  padding: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .bc-lst-otr::-webkit-scrollbar {
    display: none; }
  .bc-lst-otr + * {
    margin-top: calc(2em / 1.7); }

.bc-lst {
  height: calc(4em / 1.3);
  color: #888;
  font-size: calc(1.3em / 1.7);
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center; }
  .bc-lst_itm {
    display: flex;
    align-items: center;
    flex-shrink: 0; }
  @media screen and (max-width: 740px) {
    .bc-lst_itm:first-child {
      padding-left: 20px; }
    .bc-lst_itm:last-child {
      padding-right: 20px; } }
  @media screen and (min-width: 741px) {
    .bc-lst_itm:first-child {
      padding-left: 60px; }
    .bc-lst_itm:last-child {
      padding-right: 60px; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .bc-lst_itm:first-child {
      padding-left: 40px; }
    .bc-lst_itm:last-child {
      padding-right: 40px; } }

/* Contents side bar styles
   ======================================== */
.cnt-asd_ttl {
  color: #fff;
  font-size: calc(1.9em / 1.7);
  background-color: #016666;
  border-radius: calc(0.5em / 1.9) calc(0.5em / 1.9) 0 0;
  overflow: hidden; }

.cnt-asd_ttl-lnk {
  padding: .5em .9em;
  color: inherit !important;
  opacity: 1 !important;
  display: flex;
  align-items: center; }
  .cnt-asd_ttl-lnk::before {
    content: '';
    width: calc(1.8em / 1.9);
    height: calc(1.8em / 1.9);
    margin-right: calc(1.5em / 1.9);
    background-image: url(../img/img_icn_asd_lst.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: calc(1.8em / 1.9) calc(1.8em / 1.9);
    display: block;
    flex-shrink: 0;
    position: relative;
    top: calc(-0.1em / 1.9); }

@media screen and (max-width: 740px) {
  .cnt-asd {
    margin-top: 3em; } }

@media screen and (min-width: 741px) {
  .cnt-asd {
    width: 100%;
    max-width: calc(30em / 1.7); } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .cnt-asd {
    max-width: calc(27em / 1.7); } }

@media screen and (min-width: 741px) and (max-width: 860px) {
  .cnt-asd {
    max-width: none;
    min-width: 0;
    margin-top: calc(5em / 1.7); } }

.asd-lst {
  margin-top: calc(1.8em / 1.5);
  padding: 0 calc(2em / 1.5);
  font-size: calc(1.5em / 1.7);
  font-weight: 500;
  line-height: 1.4; }
  .-lv01 .asd-lst,
  .-lv02 .asd-lst {
    margin-top: 0; }
  .-lv01 .asd-lst {
    padding: 0 0 calc(0.8em / 1.5);
    font-size: 1em; }
  .-lv01:last-child > .asd-lst {
    padding-bottom: 0; }
  .-lv02 .asd-lst {
    padding: 0 calc(2em / 1.3) calc(1.4em / 1.3);
    font-size: calc(1.3em / 1.5); }
  .asd-lst_itm {
    border-radius: calc(0.3em / 1.5); }
    .asd-lst_itm:nth-child(n+2) {
      margin-top: calc(0.5em / 1.5); }
    .asd-lst_itm.-lv02 {
      background-color: #dee8e8; }
    .asd-lst_itm.-lv03 {
      border-radius: calc(0.3em / 1.3);
      background-color: rgba(255, 255, 255, 0.6); }
      .asd-lst_itm.-lv03:nth-child(n+2) {
        margin-top: calc(0.5em / 1.3); }
  .asd-lst_lnk {
    padding: calc(0.8em / 1.5) 0;
    color: #888 !important;
    display: flex;
    align-items: baseline;
    opacity: 1 !important;
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1) !important; }
    .-lv01 .asd-lst_lnk::before,
    .-lv03 .asd-lst_lnk::before {
      font-weight: 500;
      display: block;
      flex-shrink: 0;
      position: relative; }
    .-lv01 .asd-lst_lnk::before {
      content: '\e906';
      margin-right: calc(1em / 1.1);
      font-family: icomoon;
      font-size: calc(1.1em / 1.5);
      font-weight: 500;
      top: calc(-0.1em / 1.1); }
    .-lv02 .asd-lst_lnk {
      color: #6f8686 !important; }
    .-lv02 > .asd-lst_lnk {
      padding-right: calc(2.2em / 1.5); }
      .-lv02 > .asd-lst_lnk::before {
        visibility: hidden; }
    .-lv03 .asd-lst_lnk {
      padding-right: calc(1em / 1.3);
      padding-left: calc(1em / 1.3); }
      .-lv03 .asd-lst_lnk::before {
        content: '\e907';
        margin-right: calc(1em / 0.7);
        font-family: icomoon;
        font-size: calc(0.7em / 1.3);
        font-weight: 500;
        top: calc(-0.2em / 0.7); }
    .is-actv > .asd-lst_lnk {
      color: #016666 !important; }
  @media screen and (min-width: 741px) {
    .asd-lst_lnk:hover {
      color: #016666 !important; } }

/* Tab menu styles
   ======================================== */
.tab-menu {
  position: relative; }
  .tab-menu.-jp-syll {
    margin-top: 1.5em; }
  .tmp19 .contlist_box + .tab-menu {
    margin-top: 3.125em; }
  section + .tab-menu {
    margin-top: 2em; }

.tab-lst {
  line-height: 1.4;
  display: flex; }
  .tab-lst_itm {
    width: calc((100% - 2px * 4) / 5); }
    .tab-lst_itm:nth-last-child(n+2) {
      margin-right: 2px; }
    .tab-lst_itm.-bn-itm {
      flex-shrink: 0; }
      .tab-lst_itm.-bn-itm hr {
        width: 2px;
        height: 100%;
        margin: 0 calc(1em / 1.7);
        border: 0;
        background-color: rgba(0, 0, 0, 0.15);
        display: block; }
    .tmp19 .tab-lst_itm {
      width: calc((100% - 2px * 2) / 3); }
  .tab-lst_lnk {
    height: 100%;
    padding: calc(0.85em / 1.7) calc(0.5em / 1.7);
    color: #fff !important;
    background: #acaaa4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .is-actv .tab-lst_lnk {
      background: #199696; }
      .is-actv .tab-lst_lnk::before, .is-actv .tab-lst_lnk::after {
        content: '';
        display: block;
        position: absolute; }
      .is-actv .tab-lst_lnk::before {
        width: 100%;
        height: 3px;
        background-color: #016666;
        top: 100%;
        left: 0; }
      .is-actv .tab-lst_lnk::after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: calc(0.5em / 1.7) calc(0.7em / 1.7) 0 calc(0.7em / 1.7);
        border-color: #016666 transparent transparent transparent;
        top: calc(100% + 3px);
        left: 50%;
        transform: translate(-50%, 0); }
    .is-dsbl .tab-lst_lnk {
      background-color: #acaaa4; }
      .is-dsbl .tab-lst_lnk::before, .is-dsbl .tab-lst_lnk::after {
        display: none; }
  @media screen and (max-width: 740px) {
    .tab-lst_itm:first-child {
      min-width: calc(7.5em / 1.5); }
      .tmp2 .tab-lst_itm:first-child {
        min-width: 0; }
    .tab-lst_itm.-bn-itm hr {
      display: none; }
    .-bn-itm .tab-lst_wrp {
      display: none; } }
  @media screen and (min-width: 741px) {
    .tab-lst_itm.-bn-itm {
      min-width: calc(20em / 1.7); } }

a.tab-lst_lnk {
  background: #acaaa4; }

.bn-tgl {
  width: 1em;
  height: calc(0.2em / 1.4);
  font-size: calc(1.4em / 1.7);
  background-color: #fff;
  display: block; }
  .bn-tgl::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: inherit;
    display: block;
    transform: rotate(-90deg);
    transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1); }
  .is-shw .bn-tgl::before {
    transform: rotate(0); }

.bak-num-lst {
  padding: calc(1em / 1.7) calc(1.5em / 1.7);
  line-height: 1.4;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  border-radius: calc(0.5em / 1.7);
  background-color: #fff;
  position: absolute;
  top: calc(100% + 3px + 1.2em / 1.7);
  left: 0;
  z-index: 100;
  transform: translate(0, -15%);
  transition: opacity 600ms cubic-bezier(0.23, 1, 0.32, 1), visibility 600ms cubic-bezier(0.23, 1, 0.32, 1), transform 600ms cubic-bezier(0.23, 1, 0.32, 1); }
  .is-shw .bak-num-lst {
    visibility: visible;
    opacity: 1;
    transform: translate(0); }
  .bak-num-lst_itm {
    margin: calc(0.6em / 1.7) calc(1.5em / 1.7); }
  .bak-num-lst_lnk {
    background: none !important;
    color: #888 !important;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    opacity: 1 !important; }
    .bak-num-lst_lnk::before {
      content: '\e907';
      margin-right: 1em;
      font-family: icomoon;
      font-size: calc(1em / 1.7);
      font-weight: 500;
      display: block;
      flex-shrink: 0;
      position: relative;
      top: calc(-0.35em / 1.7); }
    .is-actv .bak-num-lst_lnk {
      color: #bb1a62 !important; }
  @media screen and (min-width: 741px) {
    .bak-num-lst_lnk {
      transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1) !important; }
      .bak-num-lst_lnk:hover {
        color: #bb1a62 !important; } }

.jp-syll-sct {
  display: none; }
  .jp-syll-sct.is-shw {
    display: block; }

/* Top page styles
   ======================================== */
.cnt-top-lst {
  color: #fff;
  font-weight: 700;
  text-align: left;
  line-height: 1.5;
  display: flex;
  gap: 4px;
  flex-wrap: wrap; }
  .cnt-top-lst_itm {
    padding: 1.4375em 1.75em 2em;
    background-color: #555;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0; }
    .cnt-top-lst_itm:nth-child(1) {
      background-color: #11aeae; }
    .cnt-top-lst_itm:nth-child(2) {
      background-color: #000; }
    .cnt-top-lst_itm:nth-child(3) {
      background-color: #002c4a; }
    .cnt-top-lst_itm:nth-child(4) {
      background-color: #ffa200; }
    .cnt-top-lst_itm:nth-child(5) {
      background-color: #000; }
    .cnt-top-lst_itm:nth-child(6) {
      background-color: #e4451a; }
    .cnt-top-lst_itm:nth-child(7) {
      background-color: #5f4b8b; }
    .cnt-top-lst_itm:nth-child(8) {
      background-color: #016666; }
    .cnt-top-lst_itm:nth-child(9) {
      background-color: #b50b57; }
    .cnt-top-lst_itm:nth-child(10) {
      background-color: #000; }
    .cnt-top-lst_itm:nth-child(11) {
      background-color: #259ae1; }
    .cnt-top-lst_itm:nth-child(12) {
      background-color: #e41a2d; }
  .cnt-top-lst_trm {
    margin-bottom: 0.5em;
    font-size: 1.25em;
    line-height: 1.4; }
  .cnt-top-lst_dsc {
    margin-top: auto;
    font-size: 0.8125em; }
  .cnt-top-lst_lnk {
    color: inherit !important;
    opacity: 1 !important;
    display: flex;
    flex-direction: column; }
    .cnt-top-lst_lnk::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
  .cnt-top-lst_lnk-txt, .cnt-top-lst_lst-upd, .cnt-top-lst_dt {
    display: flex;
    align-items: center; }
  .cnt-top-lst_lnk-txt::after {
    content: '\e909';
    margin-left: 0.5em;
    font-family: icomoon;
    font-size: 1.30769em;
    font-weight: 500;
    line-height: 1; }
  .cnt-top-lst_lst-upd {
    margin-top: 0.23077em; }
  .cnt-top-lst_dt {
    position: relative; }
    .cnt-top-lst_dt::before {
      content: '\e90c';
      margin: 0 0.4em;
      font-family: icomoon;
      font-weight: 500;
      line-height: 1; }
  .cnt-top-lst_thmb {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .cnt-top-lst_thmb img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center 25%;
      font-family: 'object-fit: cover; object-position: center 25%;'; }
  @media screen and (max-width: 740px) {
    .cnt-top-lst_itm {
      width: calc((100% - 4px) / 2);
      padding: 1em 1.2em 1.33333em; } }
  @media screen and (max-width: 425px) {
    .cnt-top-lst_itm {
      width: 100%; } }
  @media screen and (min-width: 741px) {
    .cnt-top-lst_itm {
      width: calc((100% - 4px * 3) / 4);
      min-height: 11.25em; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .cnt-top-lst_itm {
      width: calc((100% - 4px * 2) / 3); }
      .ua-ie .cnt-top-lst_itm {
        width: calc(33.33% - 4px); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .cnt-top-lst {
      font-size: 1.5rem; } }

.cnt-bnr-lst {
  margin-top: calc(2em / 1.7);
  color: #fff; }
  .cnt-bnr-lst_itm {
    padding: calc(5em / 1.7) calc(6em / 1.7);
    background-color: #333;
    background-image: url(../img/img_bg_bnr_dummy.jpg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover; }
    .cnt-bnr-lst_itm,
    .cnt-bnr-lst_itm > * {
      position: relative; }
    .cnt-bnr-lst_itm > * {
      z-index: 15; }
    .cnt-bnr-lst_itm:nth-child(n+2) {
      margin-top: calc(2em / 1.7); }
    .cnt-bnr-lst_itm::before, .cnt-bnr-lst_itm::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0; }
    .cnt-bnr-lst_itm::before {
      background-color: rgba(0, 0, 0, 0.1);
      background-image: url(../img/img_bg_dot.svg);
      background-position: left top;
      background-size: 4px 4px;
      opacity: 0.75;
      z-index: 10; }
    .cnt-bnr-lst_itm::after {
      background-image: linear-gradient(90deg, #006e6a 0%, #024e60 25%, rgba(49, 15, 68, 0.65) 70%, rgba(60, 15, 68, 0.3) 100%);
      opacity: 0.9;
      z-index: 5; }
  .cnt-bnr-lst_trm {
    font-size: calc(2.9em / 1.7);
    font-weight: 500;
    line-height: 1.4; }
  .cnt-bnr-lst_dsc {
    margin-top: calc(2.5em / 1.7); }
  @media screen and (max-width: 740px) {
    .cnt-bnr-lst_itm {
      padding: calc(2.4em / 1.5) calc(2.2em / 1.5) calc(3em / 1.5); } }
  @media screen and (max-width: 425px) {
    .cnt-bnr-lst_itm::after {
      background-image: linear-gradient(135deg, #006e6a 0%, #024e60 25%, rgba(49, 15, 68, 0.65) 70%, rgba(60, 15, 68, 0.3) 100%); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .cnt-bnr-lst {
      font-size: 1.5rem; } }

/* Sub top styles
   ======================================== */
.sub-top-lst-otr {
  padding: 0 calc(2em / 1.7) calc(2em / 1.7); }

.sub-top-lst {
  line-height: 1.4; }
  .sub-top-lst.-ch-lst {
    padding: calc(1em / 1.7) calc(1.5em / 1.7);
    border-radius: calc(0.3em / 1.7);
    background-color: #f7f7f7;
    display: flex;
    flex-wrap: wrap; }
  .sub-top-lst_itm.-lv01 {
    border-radius: calc(0.3em / 1.7);
    background-color: #e9e8e5; }
    .sub-top-lst_itm.-lv01:nth-child(n+2) {
      margin-top: calc(1.5em / 1.7); }
  .sub-top-lst_itm.-lv02 {
    margin: calc(0.6em / 1.7) calc(1.5em / 1.7); }
  .sub-top-lst_flx {
    display: flex;
    align-items: center; }
  .sub-top-lst_lnk {
    display: flex;
    align-items: baseline;
    opacity: 1 !important;
    transition: color 400ms cubic-bezier(0.23, 1, 0.32, 1) !important;
    background: none !important; }
    .sub-top-lst_lnk::before {
      font-weight: 500;
      display: block;
      flex-shrink: 0;
      position: relative; }
    .sub-top-lst_flx > .sub-top-lst_lnk {
      width: 100%;
      font-size: calc(2.1em / 1.7);
      padding: calc(2em / 2.1);
      color: #716e65; }
      .sub-top-lst_flx > .sub-top-lst_lnk::before {
        content: '\e908';
        margin-right: calc(1.5em / 1.9);
        font-family: icomoon;
        font-size: calc(1.9em / 2.1);
        font-weight: 500; }
    .-lv02 > .sub-top-lst_lnk {
      color: #888;
      font-weight: 500; }
      .-lv02 > .sub-top-lst_lnk::before {
        content: '\e907';
        margin-right: 1em;
        font-family: icomoon;
        font-size: calc(1em / 1.7);
        font-weight: 500;
        top: calc(-0.35em / 1.7); }

/* List item styles
   ======================================== */
.tmp28 .list_box,
.tmp36 .list_box {
  display: flex;
  flex-wrap: wrap; }

.list_item {
  padding: calc(3em / 1.7) 0;
  line-height: 1.5;
  /*tmp9クラスは不要。後で削除する！*/ }
  .list_item:first-child {
    padding-top: 0 !important; }
  .list_item:last-child {
    padding-bottom: 0 !important; }
  .list_item:nth-child(n+2) {
    margin-top: 0;
    border-top: solid 2px #dfdfdf; }
  .tmp28 .list_item,
  .tmp36 .list_item {
    border-top: 0; }
  .tmp2 .list_item,
  .tmp7 .list_item,
  .tmp9 .list_item,
  .tmp35 .list_item,
  .tmp26 .list_item,
  .tmp27 .list_item {
    padding: calc(5em / 1.7) 0; }
  .tmp9 .list_item,
  .tmp35 .list_item {
    display: flex;
    align-items: center; }
    .tmp9 .list_item > div,
    .tmp35 .list_item > div {
      width: 100%; }
  .tmp28 .list_item,
  .tmp36 .list_item {
    padding: 0; }
  .list_item h2 {
    margin-bottom: 0;
    padding: 0;
    font-size: calc(1.1em);
    border-bottom: 0;
    border-radius: 0;
    /*tmp27はあとで削除*/ }
    .list_item h2::before {
      display: none; }
    .tmp1 .list_item h2,
    .tmp4 .list_item h2,
    .tmp32 .list_item h2,
    .tmp37 .list_item h2,
    .tmp24 .list_item h2 {
      width: calc( 100% - 10em); }
    .tmp2 .list_item h2,
    .tmp7 .list_item h2,
    .tmp26 .list_item h2,
    .tmp27 .list_item h2 {
      width: auto;
      margin-top: 0;
      padding-right: 0;
      font-size: calc(2.5em / 1.7); }
    .tmp9 .list_item h2,
    .tmp35 .list_item h2 {
      width: auto;
      padding-right: 0;
      display: flex;
      flex-direction: column; }
      .tmp9 .list_item h2 .lng-ttl,
      .tmp35 .list_item h2 .lng-ttl {
        margin-top: calc(1em / 2.3);
        font-size: calc(2.3em / 1.9); }
    .tmp28 .list_item h2,
    .tmp36 .list_item h2 {
      width: auto;
      margin-bottom: 1em;
      padding-right: 0;
      font-size: 1em;
      text-align: center; }
  .list_item time {
    margin-bottom: calc(0.5em / 1.5);
    font-size: .9em;
    font-weight: 700;
    line-height: 1.4;
    display: block; }
    .tmp2 .list_item time,
    .tmp7 .list_item time,
    .tmp26 .list_item time,
    .tmp27 .list_item time {
      margin-bottom: calc(0.3em / 1.5); }
  .list_item ._lead {
    margin-top: 1em;
    font-size: calc(1.5em / 1.7); }
  .tmp2 .list_item > ul,
  .tmp7 .list_item > ul,
  .tmp26 .list_item > ul,
  .tmp27 .list_item > ul {
    line-height: 1.7; }
  .tmp2 .list_item > ul,
  .tmp26 .list_item > ul,
  .tmp27 .list_item > ul {
    margin-top: calc(3.4em / 1.7); }
  .tmp7 .list_item > ul {
    margin-top: calc(2.5em / 1.7); }
  .tmp2 .list_item > ul li:nth-child(n+2),
  .tmp7 .list_item > ul li:nth-child(n+2),
  .tmp26 .list_item > ul li:nth-child(n+2),
  .tmp27 .list_item > ul li:nth-child(n+2) {
    margin-top: calc(2.5em / 1.7) !important; }
  .list_item figure img {
    width: 100%; }
  .tmp9 .list_item figure,
  .tmp35 .list_item figure {
    width: 100%;
    max-width: calc(24.4em / 1.7);
    border: solid 2px #ddd;
    flex-shrink: 0; }
  .tmp28 .list_item figure,
  .tmp36 .list_item figure {
    padding: 0 1em; }
  .list_item .bt, .list_item .bt2 {
    margin-bottom: 0; }
  .list_item .more {
    justify-content: center; }
    .tmp2 .list_item .more,
    .tmp26 .list_item .more,
    .tmp27 .list_item .more {
      width: auto;
      margin-top: calc(1.4em / 1.7);
      justify-content: flex-start; }
    .tmp2 .list_item .more a,
    .tmp26 .list_item .more a,
    .tmp27 .list_item .more a {
      padding: calc(0.25em / 1.3) 1em;
      font-size: 1.3rem;
      align-items: center; }
  @media screen and (max-width: 740px) {
    .tmp28 .list_item,
    .tmp36 .list_item {
      width: calc((100% - 20px) / 2);
      margin: 0; }
      .tmp28 .list_item:nth-child(even),
      .tmp36 .list_item:nth-child(even) {
        margin-left: 20px; }
      .tmp28 .list_item:nth-child(n+3),
      .tmp36 .list_item:nth-child(n+3) {
        margin-top: 40px; }
    .tmp32 .list_item h2,
    .tmp37 .list_item h2,
    .tmp24 .list_item h2 {
      width: auto; }
    .list_item figure {
      margin: calc(2em / 1.5) auto 0; }
      .tmp28 .list_item figure,
      .tmp36 .list_item figure {
        margin: 0; }
    .list_item .more {
      max-width: calc(28em / 1.5);
      margin: calc(2em / 1.5) auto 0; }
      .tmp2 .list_item .more,
      .tmp26 .list_item .more,
      .tmp27 .list_item .more {
        max-width: none;
        margin-right: 0;
        margin-left: 0; }
      .list_item .more a {
        width: 100%;
        padding: calc(1em / 1.3) 1em; }
        .tmp2 .list_item .more a,
        .tmp26 .list_item .more a,
        .tmp27 .list_item .more a {
          width: auto; }
          .tmp2 .list_item .more a span,
          .tmp26 .list_item .more a span,
          .tmp27 .list_item .more a span {
            display: none; } }
  @media screen and (min-width: 741px) {
    .tmp9 .list_item > div,
    .tmp35 .list_item > div {
      padding-left: 40px; }
    .tmp28 .list_item,
    .tmp36 .list_item {
      border-top: none;
      width: calc((100% - 40px * 2) / 3); }
      .tmp28 .list_item:nth-child(n+2),
      .tmp36 .list_item:nth-child(n+2) {
        margin-left: 40px; }
      .tmp28 .list_item:nth-child(3n+1),
      .tmp36 .list_item:nth-child(3n+1) {
        margin-left: 0; }
      .tmp28 .list_item:nth-child(n+4),
      .tmp36 .list_item:nth-child(n+4) {
        margin-top: 50px; }
    .tmp28.ua-ie .list_item,
    .tmp36.ua-ie .list_item {
      width: calc(33.3% - 40px); }
    .list_item time + div {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
    .list_item h2 {
      order: 0;
      /*
			.tmp15 & {
				width: 100%;
				padding-right: 0;
			}
*/ }
      .tmp32 .list_item h2,
      .tmp37 .list_item h2,
      .tmp24 .list_item h2 {
        margin-right: 0.5em; }
    .list_item ._lead {
      width: 100%;
      order: 10; }
    .list_item .more {
      width: 10em;
      margin: 0;
      order: 5; }
      .list_item .more a {
        padding: calc(0.3em / 1.5) 1em; }
        .list_item .more a::after {
          margin-left: calc(2.4em / 1.6); } }
  @media screen and (min-width: 860px) and (max-width: 1050px) {
    .tmp9 .list_item > div,
    .tmp35 .list_item > div {
      padding-left: 20px; }
    .tmp28 .list_item,
    .tmp36 .list_item {
      width: calc((100% - 40px) / 2) !important;
      margin: 0; }
      .tmp28 .list_item:nth-child(n+2),
      .tmp36 .list_item:nth-child(n+2) {
        margin-left: 0; }
      .tmp28 .list_item:nth-child(even),
      .tmp36 .list_item:nth-child(even) {
        margin-left: 30px; }
      .tmp28 .list_item:nth-child(n+3),
      .tmp36 .list_item:nth-child(n+3) {
        margin-top: 50px; }
    .tmp9 .list_item figure,
    .tmp35 .list_item figure {
      max-width: calc(18em / 1.7); } }

/* FAQ styles
   ======================================== */
.faq-lst_itm {
  border-radius: calc(0.5em / 1.7);
  overflow: hidden; }
  .faq-lst_itm:nth-child(n+2) {
    margin-top: calc(0.8em / 1.7); }

.faq-lst_trm {
  padding: calc(1.4em / 1.9) calc(1.5em / 1.9);
  color: #716e65;
  font-size: calc(1.9em / 1.7);
  line-height: 1.4;
  text-align: justify;
  background-color: #d7d5cf;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .faq-lst_trm::before {
    content: '\e90b';
    font-family: icomoon;
    font-weight: 500;
    line-height: 1;
    flex-shrink: 0; }

.faq-lst_ttl {
  margin-right: auto; }

.faq-lst_tgl {
  width: calc(1.3em / 1.9);
  height: calc(0.3em / 1.9);
  margin-left: calc(1.5em / 1.9);
  background-color: currentColor;
  display: block;
  flex-shrink: 0;
  position: relative; }
  .faq-lst_tgl::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
    transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1); }
  .is-shw .faq-lst_tgl::before {
    transform: rotate(0); }

.faq-lst_dsc {
  background-color: #e9e8e5;
  display: none; }

@media screen and (max-width: 740px) {
  .faq-lst_trm::before {
    margin-right: calc(1em / 2.9);
    font-size: 2.9rem; } }

@media screen and (min-width: 741px) {
  .faq-lst_trm {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .faq-lst_trm::before {
      margin-right: calc(1em / 3.5);
      font-size: 3.5rem; }
    .faq-lst_trm:hover {
      opacity: 0.4; }
  .faq-lst_dsc::before {
    margin-right: calc(-2.8em / 3.5);
    width: calc(5.5em / 3.5);
    height: calc(5.5em / 3.5); } }

.ans-otr {
  padding: calc(1.5em / 1.7);
  display: flex;
  align-items: center; }
  .ans-otr::before {
    content: '\e90a';
    color: #bb1a62;
    font-family: icomoon;
    font-weight: 500;
    line-height: 1;
    border-radius: 50%;
    background-color: #f7f7f7;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5; }
  @media screen and (max-width: 740px) {
    .ans-otr::before {
      margin-right: calc(-2.5em / 2.9);
      width: calc(4.9em / 2.9);
      height: calc(4.9em / 2.9);
      font-size: 2.9rem; } }
  @media screen and (max-width: 425px) {
    .ans-otr {
      flex-direction: column; }
      .ans-otr::before {
        margin: 0 0 calc(-2.5em / 2.9); } }
  @media screen and (min-width: 741px) {
    .ans-otr::before {
      margin-right: calc(-2.8em / 3.5);
      width: calc(5.5em / 3.5);
      height: calc(5.5em / 3.5);
      font-size: 3.5rem; } }
  @media screen and (min-width: 860px) and (max-width: 1050px) {
    .ans-otr {
      flex-direction: column; }
      .ans-otr::before {
        margin: 0 0 calc(-2.8em / 3.5); } }

.ans {
  padding: calc(2em / 1.7) calc(2em / 1.7);
  border-radius: calc(0.5em / 1.7);
  background-color: #f7f7f7; }
  .ans * + ul,
  .ans * + ol {
    margin-top: 0.5em; }
  @media screen and (max-width: 740px) {
    .ans {
      padding-left: calc(2.6em / 1.5); } }
  @media screen and (max-width: 425px) {
    .ans {
      padding: calc(2.4em / 1.5) calc(2em / 1.7) calc(2em / 1.7); } }
  @media screen and (min-width: 741px) {
    .ans {
      padding-left: calc(3em / 1.7); } }
  @media screen and (min-width: 860px) and (max-width: 1050px) {
    .ans {
      padding: calc(2.4em / 1.7) calc(2em / 1.7) calc(2em / 1.7); } }

/* Committees styles
   ======================================== */
@media screen and (min-width: 741px) {
  .pg1 .mn-cnt_inr {
    font-size: clamp(1.4rem, 1.29032vw, 1.6rem);
    display: flex;
    gap: 1.875em; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .pg1 .mn-cnt_inr {
    gap: 1.42857em; } }

@media screen and (min-width: 741px) and (max-width: 860px) {
  .pg1 .mn-cnt_inr {
    font-size: inherit;
    display: block; } }

@media screen and (min-width: 741px) {
  .cnt-top-lst_itm {
    width: calc((100% - 4px * 2) / 3); } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .cnt-top-lst_itm {
    width: calc((100% - 4px) / 2); } }

@media screen and (min-width: 741px) and (max-width: 860px) {
  .cnt-top-lst_itm {
    width: calc((100% - 4px * 2) / 3); } }

.rbgk-tm-line {
  --inrVgap-xAxs: 0.9375em;
  margin-top: var(--otrVgap-xAxs);
  border: 2px solid rgba(51, 51, 51, 0.1);
  border-radius: 0.9375em;
  background-color: #f0f0f0;
  overflow: hidden; }
  .rbgk-tm-line_hdr {
    padding-block: 1.25em;
    padding-inline: var(--inrVgap-xAxs);
    box-shadow: 0 1.2em 1.2em -1.4em rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 25; }
  .rbgk-tm-line_bdy {
    height: 100%;
    background-color: inherit;
    position: relative;
    z-index: 0; }
  .rbgk-tm-line_ttl {
    color: #142f85;
    display: flex;
    align-items: flex-end; }
    .rbgk-tm-line_ttl-txt {
      font-size: 1.25em;
      display: block; }
      .rbgk-tm-line_ttl-txt.-lrg {
        width: 6em;
        margin-right: 0.4em; }
  @media screen and (max-width: 740px) {
    .rbgk-tm-line {
      --otrVgap-xAxs: 2em;
      font-size: clamp(1.4rem, 4.375vw, 1.5rem); } }
  @media screen and (min-width: 741px) {
    .rbgk-tm-line {
      width: 100%;
      max-width: 18.125em;
      display: flex;
      flex-direction: column;
      flex-shrink: 0; }
      .rbgk-tm-line_ttl-txt {
        font-size: 1.125em; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .rbgk-tm-line {
      font-size: clamp(1.2rem, 1.33333vw, 1.4rem); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .rbgk-tm-line {
      --otrVgap-xAxs: 2.5em;
      max-width: none;
      font-size: inherit; }
      .rbgk-tm-line_ttl-txt {
        font-size: 1.25em; } }

.tm-line-lst {
  width: 100%;
  max-height: var(--lst-maxHgt);
  padding-block: 1.875em;
  padding-inline: var(--inrVgap-xAxs);
  display: flex;
  gap: 1.5625em var(--inrVgap-xAxs);
  flex-wrap: wrap;
  overflow: scroll; }
  .tm-line-lst_lnk {
    color: inherit !important; }
    .tm-line-lst_lnk::after {
      display: none; }
  .tm-line-lst_ttl {
    color: inherit;
    font-size: 0.875em;
    margin-top: 0.75em; }
    .tm-line-lst_ttl-txt {
      display: block; }
      .tm-line-lst_ttl-txt.-dt {
        font-size: 0.85714em; }
      .tm-line-lst_ttl-txt + .tm-line-lst_ttl-txt {
        margin-top: 0.25em; }
  .tm-line-lst_fig {
    aspect-ratio: 65 / 28;
    color: #142f85;
    border-radius: 0.1875em;
    overflow: hidden;
    box-shadow: 0 1.2em 1.2em -1.4em rgba(0, 0, 0, 0.25);
    background-color: #fff;
    position: relative; }
    .tm-line-lst_fig-img {
      display: block;
      position: relative;
      z-index: 10; }
      .tm-line-lst_fig-img.-thmb {
        height: 100%; }
        .tm-line-lst_fig-img.-thmb > * {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .tm-line-lst_fig-img.-rbgk {
        width: 64.41718%;
        aspect-ratio: 163 / 53;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate3d(-50%, -50%, 0); }
  @media screen and (max-width: 740px) {
    .tm-line-lst {
      --lst-maxHgt: 21.33333em;
      font-size: clamp(1.2rem, 2.82353vw, 1.5rem); }
      .tm-line-lst_itm {
        width: calc((100% - var(--inrVgap-xAxs)) / 2); } }
  @media screen and (max-width: 425px) {
    .tm-line-lst {
      font-size: inherit; }
      .tm-line-lst_itm {
        width: 100%; } }
  @media screen and (min-width: 741px) {
    .tm-line-lst {
      height: 100%;
      position: absolute; }
      .tm-line-lst_itm {
        width: 100%; } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .tm-line-lst {
      --lst-maxHgt: 22.5em;
      position: static; }
      .tm-line-lst_itm {
        width: calc((100% - var(--inrVgap-xAxs)) / 2); } }

/* Robogaku timeline styles
   ======================================== */
.mn-art * + section.cmt-lst {
  margin-top: .5em; }

.cmt-lst, .cmt-lst_itm {
  border-radius: calc(0.3em / 1.7);
  overflow: hidden; }

.cmt-lst_itm:nth-child(n+2) {
  margin-top: calc(0.8em / 1.7); }

.cmt-lst_trm, .cmt-lst_dsc {
  background-color: #f7f7f7; }

.cmt-lst header {
  padding: .5em 1em;
  color: #716e65;
  font-size: calc(1.9em / 1.7);
  background-color: #e9e8e5;
  display: flex;
  align-items: baseline; }
  .cmt-lst header h5 {
    width: 50%;
    padding-right: 1em; }
  .cmt-lst header .items,
  .cmt-lst header .leader {
    margin-top: 0 !important;
    font-size: .8em; }
  .cmt-lst header .leader::before {
    content: "委員長："; }
  .cmt-lst header .btn.-acrd-btn {
    margin-bottom: 0; }

.cmt-lst main {
  border: 5px solid #e9e8e5;
  padding: 1em; }
  .cmt-lst main * + ul,
  .cmt-lst main * + ol {
    margin-top: 0.5em; }
  .cmt-lst main .more {
    margin-bottom: 0; }

/* 403 styles
   ======================================== */
@media screen and (max-width: 740px) {
  body.p-403 {
    font-size: 1.4rem; } }

.p-403 .mn-cnt {
  padding: 0;
  overflow: hidden; }

.art-403 {
  line-height: 1.7;
  text-align: justify; }
  .art-403_bdy {
    max-width: 62.5em;
    margin: 0 auto;
    position: relative; }
  .art-403_inr {
    max-width: 31.25em;
    position: relative;
    z-index: 10; }
  .art-403_intr {
    margin-top: 1.5em; }
  @media screen and (max-width: 740px) {
    .art-403 {
      padding: 2.85714em 1.42857em; }
      .art-403_inr {
        margin: 0 auto; }
      .art-403_intr {
        font-size: 1.14286em; } }
  @media screen and (min-width: 741px) {
    .art-403 {
      padding: 5em 2.5em; }
      .art-403_bdy::after {
        content: '';
        width: 56.53061vw;
        height: 58.16327vw;
        max-width: 34.625em;
        max-height: 35.625em;
        background-image: url(../img/img_403.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        display: block;
        position: absolute;
        bottom: 0.5%;
        left: 45%;
        z-index: 5; }
      .art-403_inr {
        width: 52.08333%; }
      .art-403_intr {
        font-size: 1.25em; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .art-403 {
      padding-top: 7.61905vw; }
      .art-403_intr {
        font-size: calc(20 / 970 * (100vw - 80px)); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .art-403_bdy::after {
      display: none; }
    .art-403_inr {
      width: 100%;
      margin: 0 auto; }
    .art-403_intr {
      font-size: 1.25em; } }

.sct-en-403 {
  margin-top: 1.25em;
  color: #888;
  font-weight: 700;
  line-height: 1.5;
  text-align: left; }
  .sct-en-403_ttl {
    color: #016666;
    font-weight: 900; }
  .sct-en-403_cpy {
    margin-top: 0.625em; }
  @media screen and (max-width: 740px) {
    .sct-en-403_ttl {
      text-align: center; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .sct-en-403 {
      font-size: calc(16 / 970 * (100vw - 80px)); } }
  @media screen and (min-width: 741px) and (max-width: 860px) {
    .sct-en-403 {
      font-size: inherit; }
      .sct-en-403_ttl {
        text-align: center; } }

/* Modal styles
   ======================================== */
.mdl {
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9500; }
  .mdl.is-opn {
    opacity: 1;
    visibility: visible; }
  .is-load .mdl {
    transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1), visibility 250ms cubic-bezier(0.23, 1, 0.32, 1); }
  .mdl_bdy {
    width: 90vw;
    max-width: 1000px;
    opacity: 0;
    position: relative;
    z-index: 10;
    transform: scale(0.75); }
    .-pdf .mdl_bdy {
      max-width: none; }
    .is-opn .mdl_bdy {
      opacity: 1;
      transform: scale(1); }
      .is-load .is-opn .mdl_bdy {
        transition: opacity 250ms 138.88889ms cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 250ms 138.88889ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
    .is-load .mdl_bdy {
      transition: opacity 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 250ms cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  .mdl_pdf {
    width: 100%;
    border: 0; }
  .mdl_cls {
    overflow: hidden;
    position: absolute;
    bottom: calc(100% + 1.2em);
    z-index: 15;
    cursor: pointer;
    transform: rotate(45deg); }
    .mdl_cls::before, .mdl_cls::after {
      content: '';
      background-color: #fff;
      opacity: 0;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .is-opn .mdl_cls::before, .is-opn .mdl_cls::after {
        opacity: 1; }
        .is-load .is-opn .mdl_cls::before, .is-load .is-opn .mdl_cls::after {
          transition: opacity 250ms 375ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 375ms cubic-bezier(0.23, 1, 0.32, 1); }
      .is-load .mdl_cls::before, .is-load .mdl_cls::after {
        transition: opacity 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms cubic-bezier(0.23, 1, 0.32, 1); }
    .mdl_cls::before {
      width: 100%; }
    .mdl_cls::after {
      height: 100%; }
  .mdl_ovrly {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.75;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5; }
  @media screen and (max-width: 740px) {
    .-pdf .mdl_bdy {
      width: 95vw; }
    .mdl_pdf {
      height: 78vh; }
    .mdl_cls {
      width: 27px;
      height: 27px;
      right: -1%; }
      .mdl_cls::before {
        height: 3px; }
      .mdl_cls::after {
        width: 3px; } }
  @media screen and (min-width: 741px) {
    .mdl_pdf {
      height: 85vh; }
    .mdl_cls {
      width: 36px;
      height: 36px;
      left: 100%; }
      .mdl_cls::before {
        height: 4px; }
      .mdl_cls::after {
        width: 4px; } }
  @media screen and (min-width: 741px) and (max-width: 1050px) {
    .mdl_cls {
      right: -1%;
      left: auto; } }

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
/*
 * text classes
 */
.utl-fwn {
  font-weight: 400; }

.utl-fwb {
  font-weight: 600; }

.utl-fwnsnsjp-thin {
  font-weight: 100; }

.utl-fwnsnsjp-light {
  font-weight: 300; }

.utl-fwnsnsjp-regular {
  font-weight: 400; }

.utl-fwnsnsjp-medium {
  font-weight: 500; }

.utl-fwnsnsjp-bold {
  font-weight: 700; }

.utl-fwnsnsjp-black {
  font-weight: 900; }

.utl-tal {
  text-align: left; }

.utl-tar {
  text-align: right; }

.utl-tac {
  text-align: center; }

.utl-taj {
  text-align: justify; }

/*
 * content display classes
 */
.utl-di {
  display: inline; }

.utl-db {
  display: block; }

.utl-dib {
  display: inline-block; }

.utl-dflx {
  display: flex; }

.utl-diflx {
  display: inline-flex; }

/*
 * position classes
 */
.utl-rltv {
  position: relative; }

.utl-absl {
  position: absolute; }

.utl-fxd {
  position: fixed; }

/*
 * link disable
 */
.utl-nolnk {
  pointer-events: none; }

/*
 * anchor block
 */
.utl-anch-blk a, a.utl-anch-blk {
  display: block; }

/*
 * text break
 */
.utl-br {
  display: inline-block; }

/*
 * text indent
 */
.utl-indent {
  text-indent: 1em; }

.utl-neg-indent {
  margin-left: 1em;
  text-indent: -1em; }

/*
 * image replacement
 */
.utl-ir {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

/*
 * mouse over action
 */
@media screen and (min-width: 741px) {
  .utl-ovr {
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1); }
    .utl-ovr:hover {
      opacity: 0.4; } }

/*
 * add pointer effect
 */
.utl-ptr {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .utl-scroll-bar-hdn::-webkit-scrollbar {
    display: none; }

/*
 * change svg color
 */
.utl-chg-svg-col {
  fill: currentColor; }

/*
 * clear fix
 */
.utl-cf::before, .utl-cf::after {
  content: '';
  display: table; }

.utl-cf::after {
  clear: both; }

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
  visibility: hidden; }

/*
 * hide visually and from screen readers
 */
.utl-hdn {
  display: none !important; }

@media screen and (max-width: 740px) {
  .utl-mbl-hdn {
    display: none; } }

@media screen and (min-width: 741px) {
  .utl-pc-hdn {
    display: none; } }

@media screen and (min-width: 741px) and (max-width: 1050px) {
  .utl-tblt-hdn {
    display: none; } }

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn, .utl-visually-hdn-foc {
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0); }

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc:active, .utl-visually-hdn-foc:focus {
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  clip: auto; }

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@media print {
  *, *::before, *::after, *::first-letter, *::first-line {
    color: #000 !important;
    /* Black prints faster */
    text-shadow: none !important;
    box-shadow: none !important;
    background: transparent !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: ' (" attr(href) ")'; }
  a[href^="#"]::after, a[href^="javascript:"]::after {
    content: ''; }
  abbr[title]::after {
    content: ' (" attr(title) ")'; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: solid 1px #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/* ==============================================================================================
   Other styles
   ============================================================================================== */
.container .list_box .list_item {
  padding: 10px 0;
  border-top: 1px dotted #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*
      .infobox{
        margin-left: 20px; font-size: .9em;
        color: red;
       }
*/
  /*
      ._cate{ width: 60px; background: #b0b0b0; color: #fff; padding: 3px; font-size: .7rem; margin-right: 10px; text-align: center;}
      ._news{ background: #1982c2;}
      ._history{ background: #ad8516;}
      ._rij{ background: #51a844;}
      

      
      ._author{ width: 20%;  min-width: 120px; font-size: .8rem;}
      ._year{ width: 120px;  font-size: .8rem; text-align: center;}*/ }
  .container .list_box .list_item > div {
    width: calc(100% - 220px); }
  .container .list_box .list_item ._lead {
    padding-top: 5px;
    font-size: .8rem; }
  .container .list_box .list_item time {
    width: 80px;
    text-align: center;
    font-size: .8rem; }
  .container .list_box .list_item h2 {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0; }
  .container .list_box .list_item .bt {
    width: 100px;
    text-align: center; }
  .container .list_box .list_item a {
    font-size: .7rem;
    padding: 10px; }

.container .contlist_box .list_item {
  margin-bottom: 30px; }
  .container .contlist_box .list_item h2 {
    font-size: 1.2rem;
    line-height: 1.5;
    margin: .5em 0; }
  .container .contlist_box .list_item .bt {
    display: block;
    padding-top: 10px; }

/*
.c_rij .container{

  .list_box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }
  
  .list_item{
    width: 49%; 
  }
}

@media screen and (max-width:768px) {
 .c_rij .container{

  .list_box{
      display: block;
  }
  
  .list_item{
    width: 100%; 
  }
} 
  
}
*/
.mn-art table {
  font-size: calc(.9em);
  line-height: 1.5;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 5px;
  background-color: #e9e8e5; }

.mn-art * + table,
.mn-art * + .tbl-scrl {
  margin-top: 1em; }

.mn-art th, .mn-art td {
  padding: 0.5em;
  border-radius: 5px; }

.mn-art th {
  color: #716e65;
  background-color: #d7d5cf; }

.mn-art td {
  background-color: #fff; }

.pg98 .mn-art th, .pg98 .mn-art td {
  width: 5.5em !important; }
  .pg98 .mn-art th:first-child, .pg98 .mn-art td:first-child {
    width: auto !important; }
  .pg98 .mn-art th:last-child, .pg98 .mn-art td:last-child {
    width: 3em !important; }

@media screen and (max-width: 740px) {
  .mn-art .nbd {
    margin-top: 1.8em; } }

/*細かいテーブル*/
table.tbl_thin {
  background-color: #716e65;
  font-size: calc(.7em);
  border-spacing: 1px; }
  table.tbl_thin th, table.tbl_thin td {
    border-radius: 0; }

/*開催日・開催地テーブル*/
table.tbl_date th {
  width: 20%; }

table.tbl_date + ._lead {
  margin-top: 1.5em;
  margin-bottom: 1em;
  /* border-bottom: 1px solid $siteCol-brn02;  */ }

.tbl-scrl {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .tbl-scrl::-webkit-scrollbar {
    display: none; }
  .tbl-scrl > table {
    min-width: 730px; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3ffeb inset; }

.form-main input[type="text"],
.form-main input[type="email"],
.form-main textarea,
.form-main select {
  font-size: 1.6rem;
  padding: 10px;
  padding-right: 45px;
  border: none;
  /*   background: #f2f2f2; */
  width: 100%;
  height: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0; }

.form-main ::-webkit-input-placeholder {
  color: #c5aa7f;
  font-size: 12px; }

.form-main input::-ms-expand,
.form-main textarea::-ms-expand,
.form-main select::-ms-expand {
  display: none; }

.form-main select {
  background: url(/images/select.png) right center no-repeat;
  /*   position: relative; */ }

.form-main p {
  line-height: 1.7; }

.form-main td p {
  padding: 10px;
  background: #f3ebdf; }

.form-main table {
  border-spacing: 0 10px;
  border-collapse: separate;
  margin-top: 1em; }

.form-main table, .form-main th, .form-main td {
  border: none;
  border-radius: 0; }

.form-main th {
  width: 30%; }

.form-main td {
  padding: 0;
  background-color: #fff; }

.form-main td.lh0 {
  line-height: 0; }

.form-main .tpt {
  background: none; }

.form-main.form-conf td {
  padding: 10px;
  border-spacing: 0;
  line-height: 1.5; }

.form-main .req {
  color: #ff0000;
  font-size: 0.8em;
  margin-left: 2px; }

.form-main .error {
  color: #ff0000; }

.form-main #comments, .form-main #anq1 {
  height: 6em;
  margin: 0; }
  .form-main #comments._large, .form-main #anq1._large {
    height: 20em; }

.form-main .form_names input {
  width: 40%; }

.form-main input[type="text"].input_half,
.form-main input[type="email"].input_half {
  width: 50%; }

.form-main .radio_area {
  background-color: #fff;
  padding: 5px; }
  .form-main .radio_area span {
    display: inline-block;
    margin: 5px; }
  .form-main .radio_area input {
    display: none; }
  .form-main .radio_area label {
    display: inline-block;
    background: #888;
    color: #fff;
    padding: 10px 15px;
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    line-height: 1; }

.form-main input:checked + label {
  background: #4F59AD;
  color: #fff; }

.form-main .buttons {
  margin-top: 30px;
  text-align: center; }

.form-main .buttons > * {
  padding: 10px;
  width: 30%;
  display: inline-block;
  cursor: pointer;
  border: none;
  font-size: 1.8rem;
  padding: 10px;
  line-height: 1.5;
  color: #fff;
  background: #888; }

.form-main .buttons input[type="submit"] {
  background: #4F59AD;
  color: #fff; }

.form-main .buttons * + * {
  margin-left: 20px; }

.form-main .form-conf .buttons input[type="submit"]:nth-child(1),
.form-main .buttons input[type="reset"] {
  color: #fff;
  background: #888; }

@media screen and (max-width: 750px) {
  .form-main table {
    width: 100%;
    min-width: auto; }
  .form-main table th,
  .form-main table td {
    display: block;
    width: 100%; }
  .form-main table th {
    padding: 2px; }
  .form-main table td {
    text-align: center; }
  .form-main.form-conf td {
    background-color: #fff;
    padding: 10px; }
  .form-main .input_half {
    width: 100%; }
  .form-main .buttons input {
    width: 45%; }
  .form-main input[type="text"].input_half,
  .form-main input[type="email"].input_half {
    width: 100%; } }

/*サイトマップ*/
.s_sitemap {
  column-count: 3; }
  .s_sitemap ul + ul {
    margin-top: 1em; }
  .s_sitemap ul li {
    margin: .5em 0 .5em 1em; }
  .s_sitemap ul .lev1 > a {
    display: block;
    font-size: 1.1em;
    color: #fff;
    background-color: #4F59AD;
    border-radius: calc(0.5em / 1.9) calc(0.5em / 1.9) 0 0;
    padding: .3em 1em; }
  .s_sitemap ul .lev1 + .lev1 {
    margin-top: 2em; }
  .s_sitemap ul .lev2 > a::before {
    content: '\e906';
    margin-right: calc(1em / 1.1);
    font-family: icomoon;
    font-size: calc(1.1em / 1.5);
    font-weight: 500;
    top: calc(-0.1em / 1.1); }
  .s_sitemap ul .lev2 + .lev2 {
    margin-top: 1em; }
  .s_sitemap ul .lev3 {
    margin-left: 3em;
    font-size: 1.2rem; }

@media screen and (min-width: 741px) and (max-width: 860px) {
  .s_sitemap {
    column-count: 2; } }

@media screen and (max-width: 425px) {
  .s_sitemap {
    column-count: 1; } }

/*学術講演会*/
.tmp11 .mn-art table {
  border-spacing: calc(0.8em / 3); }

.tmp11 .mn-art th {
  padding: .25em;
  font-size: 1.3rem; }

.tmp11 .mn-art td {
  padding: .25em; }
  .tmp11 .mn-art td .bt, .tmp11 .mn-art td .bt2 {
    margin: .25em auto; }
  .tmp11 .mn-art td.grn {
    background-color: #f7f7f7 !important; }
  .tmp11 .mn-art td.grn.disable a {
    opacity: 0.5 !important;
    pointer-events: none; }

.tmp11 .mn-art .contlist_past table {
  font-size: 1.5rem; }
  .tmp11 .mn-art .contlist_past table td:nth-of-type(1),
  .tmp11 .mn-art .contlist_past table td:nth-of-type(3),
  .tmp11 .mn-art .contlist_past table td:nth-of-type(4) {
    text-align: center; }

.tmp5 time,
.tmp33 time {
  font-size: .9em;
  transform: translateY(-2em);
  display: block;
  text-align: right;
  padding-right: 2em; }

.pg22 .mn-art .frame, .pg107 .mn-art .frame {
  margin: 1em 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1em; }
  .pg22 .mn-art .frame figure, .pg107 .mn-art .frame figure {
    padding: 10px;
    width: calc(100% / 3 - 1px); }
  .pg22 .mn-art .frame img, .pg107 .mn-art .frame img {
    height: 60px; }
  .pg22 .mn-art .frame figcaption, .pg107 .mn-art .frame figcaption {
    display: block; }

/*共催行事*/
table.tbl_kyosai th:nth-of-type(1) {
  width: 30%; }

table.tbl_kyosai th:nth-of-type(2) {
  width: 30%; }

table.tbl_kyosai th:nth-of-type(3) {
  width: 16%; }

table.tbl_kyosai th:nth-of-type(4) {
  width: 12%; }

table.tbl_kyosai th:nth-of-type(5) {
  width: 12%; }

table.tbl_kyosai td:nth-of-type(n+3) {
  text-align: center; }

.pg48 .mn-art table.tbl_thin th:nth-of-type(1) {
  width: 3em; }

/*フェロー 後で修正*/
.pg44 .mn-art td ul {
  display: flex;
  flex-wrap: wrap; }
  .pg44 .mn-art td ul li {
    width: 30%;
    margin-top: 0 !important; }

.cnt-acrd {
  display: none; }

.col2 {
  flex-wrap: wrap;
  justify-content: center; }
  @media screen and (max-width: 740px) {
    .col2 > * {
      /*
			&:nth-child(n+2) {
				margin-top: 1em;
			}
*/ } }
  @media screen and (min-width: 741px) {
    .col2 {
      display: flex; }
      .col2 > * {
        margin-right: 1%;
        margin-left: 1%;
        width: 48%;
        /*
			&:nth-child(n+2) {
				margin-left: $mbl-pad;
			}
*/ } }
  * + .col2 {
    margin-top: 1em; }

.mn-art .frame {
  background-color: #fff;
  padding: 1em;
  border: 2px solid #ddd; }
  .mn-art .frame._red {
    border: 2px solid #bb1a62; }
  .mn-art .frame h3, .mn-art .frame h4 {
    padding-left: 0;
    padding-bottom: .3em;
    border-bottom: solid 1px #ddd; }
  .mn-art .frame h3::before {
    width: 0;
    height: 0;
    border: none;
    border-right: solid calc(0.75em / 2.1) transparent;
    border-bottom: solid calc(0.75em / 2.1) transparent;
    border-top-left-radius: calc(0.5em / 2.1); }

.mn-art .lst01 {
  counter-reset: counter-ol; }
  .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
    color: #716e65; }
    .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li {
      padding: 1em;
      border-radius: calc(0.3em / 1.7);
      background-color: #e9e8e5;
      position: relative; }
      .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li:nth-child(n+2) {
        margin-top: calc(0.8em / 1.7); }
      .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li::before {
        position: absolute;
        top: 1em;
        left: 1em; }
      .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) li li {
        background-color: #f7f7f7; }
    .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ul, .mn-art .lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ol {
      margin-top: 1em;
      position: relative; }

.mn-art ul.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li {
  padding-left: 2em; }
  .mn-art ul.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li::before {
    content: '・'; }
  .mn-art ul.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ul, .mn-art ul.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ol {
    width: calc(100% + 1em);
    left: -1em; }

.mn-art ol.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li {
  padding-left: calc(3.6em / 1.7 + 1em);
  counter-increment: counter-ol; }
  .mn-art ol.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li::before {
    content: counter(counter-ol) "．";
    width: calc(3.6em / 1.7);
    text-align: right; }
  .mn-art ol.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ul, .mn-art ol.lst01:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) > li ol {
    width: calc(100% + 3.6em / 1.7);
    left: calc(-3.6em / 1.7); }
