// General logo styles
// =============================================================================
.logo {
	width: 100%;
	line-height: 1;
	display: block;
	&.-mh-rsj-logo {
		max-width: calc(20.2em / 1.7);
		margin-right: auto;
		flex-shrink: 0;
		.pg1 &, .pg2 & {
			max-width: calc(34em / 1.7);
			flex-shrink: 1;
		}
		.fxd-nav & {
			max-width: calc(32em / 1.7);
			margin: 0 auto;
		}
		.ua-ie-lgcy & {
			height: 3.4 / 1.7 + 0em;
		}
		.pg1.ua-ie-lgcy &, .pg2.ua-ie-lgcy & {
			height: 5.8 / 1.7 + 0em;
		}
	}
	&.-mf-rsj-logo {
		max-width: calc(40em / 1.7);
		.ua-ie-lgcy & {
			height: 6.8 / 1.7 + 0em;
		}
	}
	&.-rbgk-logo {
		max-width: calc(10.8em / 1.7);
		margin-left: calc(3em / 1.7);
		flex-shrink: 0;
		.ua-ie-lgcy & {
			height: 3.5 / 1.7 + 0em;
		}
	}
	&.-rsj-403-logo {
		max-width: 50 / 1.6 + 0em;
	}
	&_lnk,
	&_wrp {
		width: 100%;
		height: 100%;
		display: block;
		.-mh-rsj-logo &,
		.-rsj-403-logo & {
			color: $siteCol-rsj-grn !important;
		}
		.-mf-rsj-logo & {
			color: $siteCol-f-wht !important;
		}
		.-rbgk-logo & {
			color: $siteCol-rbgk-blu !important;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-mh-rsj-logo {
			overflow: hidden;
			.pg1 &, .pg2 & {
				width: calc(9.5em / 1.5);
				font-size: 1.7rem;
			}
			.fxd-nav-otr & {
				width: auto;
				max-width: calc(28em / 1.8);
			}
		}
		&.-mf-rsj-logo {
			margin: 0 auto;
		}
		&.-rbgk-logo {
			max-width: calc(8em / 1.5);
			margin-left: 12px;
		}
		&.-rsj-403-logo {
			width: 360 / 386 * 100%;
			margin: 0 auto;
		}
		&_lnk {
			.-mh-rsj-logo & {
				.pg1 &, .pg2 & {
					width: calc(23em / 1.5);
				}
				.fxd-nav-otr & {
					width: auto;
				}
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&.-mh-rsj-logo {
			width: calc(7.3em / 1.5);
		}
		&.-rsj-403-logo {
			max-width: 32 / 1.4 + 0em;
		}
		&_lnk {
			.-mh-rsj-logo & {
				width: calc(17.8em / 1.5);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&.-mf-rsj-logo {
			margin: 0 auto;
		}
	}
}
