
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3ffeb inset;
}


.form-main{
  //font-size: 1.4rem;
  
  input[type="text"],
  input[type="email"],
  textarea,
  select{
    font-size: 1.6rem;
    padding: 10px;
    padding-right: 45px;
    border: none;
  /*   background: #f2f2f2; */
  width: 100%;
  height: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  }
  
  ::-webkit-input-placeholder {
      color: #c5aa7f;
      font-size: 12px;
  }
  
  input::-ms-expand,
  textarea::-ms-expand,
  select::-ms-expand  {
      display: none;
  }
  
  
  select{
    background: url(/images/select.png) right center no-repeat;
  /*   position: relative; */
    }
  
  p{
    line-height: 1.7;
  }
  
  td p{
    padding: 10px;
//     border-bottom: 1px solid $siteCol-wht01;
    background: #f3ebdf;
  } 
  
  table{border-spacing:0 10px; border-collapse: separate; margin-top: 1em;}
  table,th,td{ border:none; border-radius: 0;}
  th{width: 30%; }
  td{padding: 0; background-color: #fff;}
  td.lh0{line-height: 0;}
  .tpt{background: none;}
  &.form-conf td{padding: 10px; border-spacing:0; line-height: 1.5;}
  
  
  .req{
    color: #ff0000;
    font-size: 0.8em;
    margin-left: 2px;
  }
  
  .error{
    color: #ff0000;
  }
  
  
  #comments,#anq1{
    height: 6em;
    margin: 0;
    &._large{
      height: 20em;
    }
    }
  
  .form_names input{
    width: 40%;
  }
  
  input[type="text"].input_half,
  input[type="email"].input_half{width: 50%;}
  
  .radio_area{
    background-color: #fff;
    padding: 5px;
    span{display: inline-block; margin: 5px;}
    input{display: none;}
    label{ display:inline-block; background: $siteCol-gly02;
      color: #fff;
      padding: 10px 15px; border-radius: 50px;
      cursor: pointer;
      user-select: none;
      line-height: 1;
    }
  }
    
  input:checked + label {
      background: $siteCol-d-blu02;
      color: #fff;
  }
  
  .buttons{
    margin-top: 30px;
    text-align: center;
  }
  
  .buttons > *{
    padding: 10px;
    width: 30%;
    display: inline-block;
    cursor: pointer;
    border: none;
    font-size: 1.8rem;
    padding: 10px;
    line-height: 1.5;
    color: #fff;
    background: $siteCol-gly02;
  }
  
  .buttons input[type="submit"]{
    background: $siteCol-d-blu02;
    color: #fff;
  }
  
  .buttons * + *{
    margin-left: 20px;
  }
  
  .form-conf .buttons input[type="submit"]:nth-child(1),
  .buttons input[type="reset"]{
    color: #fff;
    background: $siteCol-gly02;
  }

}

@media screen and (max-width: 750px) {
  .form-main {
    table {
      width: 100%;
      min-width: auto;
    }
    table th,
    table td{
  	  display: block;
  	  width: 100%;
    }
    
    table th{
      padding: 2px;
    }
    
    table td{
      text-align: center;
    }
    
    &.form-conf td{background-color: #fff; padding: 10px;}
    
    .input_half{width: 100%;}
    
    .buttons input{
      width: 45%;
    }
    input[type="text"].input_half,
    input[type="email"].input_half{width: 100%;}
  }
}