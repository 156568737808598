// AjaxSearch styles
// =============================================================================
#ajaxSearch {
	&_form {
		width: 100%;
		margin-right: auto;
		.fxd-nav & {
			margin-top: calc(2em / 1.7);
		}
		label {
			display: block;
			&:nth-child(3) {
				width: 1px;
				height: 1px;
				margin: -1px;
				padding: 0;
				border: 0;
				overflow: hidden;
				position: absolute;
				clip: rect(0 0 0 0);
			}
		}
	}
	&_input {
		height: 30px;
		width: 100%;
		padding: 0 1em 0 calc(5.2em / 1.5);
		color: $siteCol-gly02;
		line-height: 1;
		border: 0;
		border-radius: 100em;
		background: {
			color: $siteCol-gly01;
			image: imgUrl('img_icn_srch.svg');
			repeat: no-repeat;
			position: $icn-srch-size-pc center;
			size: $icn-srch-size-pc $icn-srch-size-pc;
		}
		display: block;
		-moz-appearance: none;
		-webkit-appearance: none;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_input {
			padding-left: calc(#{$icn-srch-size-mbl} * 2 + 0.7em);
			font-size: $fz16;
			background: {
				position: $icn-srch-size-mbl center;
				size: $icn-srch-size-mbl $icn-srch-size-mbl;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_form {
			.mn-nav & {
				max-width: 30em;
			}
		}
		&_input {
			//height: calc(3.3em / 1.5);
			font-size: calc(1.5em / 1.7);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_form {
			.mn-nav & {
				max-width: none;
			}
		}
		&_input {
			height: 30px;
		}
	}
}

.ajaxSearch {
	&_paging {
		margin-top: 3 / 1.7 + 0em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&_paging-ttl {
		margin-bottom: 0.6 / 1.9 + 0em;
		color: $siteCol-rsj-grn;
		font: {
			size: 1.9 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
	}
	&_paging-lst {
		margin: -1px;
		color: $siteCol-f-wht;
		font: {
			size: 1.4 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		display: flex;
		flex-wrap: wrap;
		& > * {
			width: 2em;
			height: 2em;
			margin: 1px;
			background-color: $siteCol-rsj-grn;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&_currentPage {
		background-color: $siteCol-gly07;
		opacity: 1 !important;
	}
	&_result {
		margin: 3 / 1.7 + 0em 0 0;
		padding: 0;
		border: 0;
		&:nth-child(n+4) {
			margin-top: 1em;
			padding-top: 1em;
			border-top: solid 2px rgba($siteCol-blk01, 0.2);
		}
	}
	&_resultLink {
		color: $siteCol-rsj-grn !important;
		font: {
			size: 2.1 / 1.7 + 0em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
	}
	&_resultExtract {
		color: $siteCol-gly03;
		font-size: 1.5 / 1.7 + 0em;
		word-wrap: break-word;
		p {
			margin-top: 0.5em;
		}
	}
	&_highlight {
		color: $siteCol-pnk01;
		font-weight: $fwNsnsJp-bold;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_paging-lst {
			& > * {
				transition: opacity $overTime $easeOutQuint;
				&:hover {
					opacity: 0.4;
				}
			}
		}
	}
}

.doc-srch {
	width: 100%;
	color: $siteCol-blk01;
	.srch-otr & {
		@extend %extend--inner-box-1200;
		margin: 0 auto;
	}
	.fxd-nav & {
		max-width: none;
		margin-top: 2 / 1.7 + 0em;
	}
	&_bdy {
		width: 100%;
		display: block;
	}
	&_flx {
		display: flex;
		align-items: center;
		&.-rdo {
			padding: 0 1em;
			.fxd-nav & {
				position: static;
			}
		}
		& > * {
			&:nth-child(2) {
				margin-left: 1em;
			}
		}
	}
	&_kw {
		height: 30px;
		width: 100%;
		padding: 0 1em 0 calc(5.2em / 1.5);
		color: $siteCol-gly02;
		line-height: 1;
		border: 0;
		border-radius: 100em;
		background: {
			color: $siteCol-gly01;
			image: imgUrl('img_icn_srch.svg');
			repeat: no-repeat;
			position: $icn-srch-size-pc center;
			size: $icn-srch-size-pc $icn-srch-size-pc;
		}
		display: block;
		-moz-appearance: none;
		-webkit-appearance: none;
	}
	&_rdo-lbl {
		margin: 0;
		line-height: 1;
		display: block;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		position: relative;
		&_flx {
			&.-rdo {
				margin-top: 0.9em;
				position: absolute;
				top: 100%;
				left: 0;
			}
		}
		&_kw {
			padding-left: calc(#{$icn-srch-size-mbl} * 2 + 0.7em);
			font-size: $fz16;
			background: {
				position: $icn-srch-size-mbl center;
				size: $icn-srch-size-mbl $icn-srch-size-mbl;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_flx {
			&.-rdo {
				.mn-nav & {
					margin-top: 0.8em;
					justify-content: center;
					position: static;
					display: none;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		align-items: center;
		.fxd-nav & {
			display: block;
		}
		&_bdy {
			max-width: 30em;
			.fxd-nav & {
				max-width: none;
			}
		}
		&_flx {
			&.-rdo {
				.fxd-nav & {
					margin-top: 0.8em;
				}
			}
		}
		&_kw {
			font-size: calc(1.5em / 1.7);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		display: block;
		position: relative;
		&_bdy {
			max-width: none;
		}
		&_flx {
			&.-rdo {
				margin-top: 0.9em;
				position: absolute;
				top: 100%;
				left: 0;
			}
		}
		&_kw {
			height: 30px;
		}
	}
}

.rslt-txt {
	margin-top: 1.5em !important;
	line-height: 1.5;
}

.prev_next {
	margin-bottom: 3em;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	
	
	span {
		a {
		  color: #fff;
			background-color: $siteCol-rsj-grn;
		}
	}
	& > span {
	  margin-bottom: 5px;
		&:nth-child(n+2) {
			margin-left: 5px;
		}
		& > * {
			min-width: 3em;
			height: 2.7em;
			padding: 0.5em !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			background-color: #ccc;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		& > span {
			& > * {
// 				width: 5em;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		span {
			a {
				transition: opacity $overTime $easeOutQuint;
				&:hover {
					color: $siteCol-f-wht;
					opacity: 0.4;
				}
			}
		}
	}
}