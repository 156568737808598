// Committees styles
// =============================================================================


.mn-art * + section.cmt-lst {
    margin-top: .5em;
  }

.cmt-lst {
	&,
	&_itm {
		border-radius: calc(0.3em / 1.7);
		overflow: hidden;
	}
	&_itm {
		&:nth-child(n+2) {
			margin-top: calc(0.8em / 1.7);
		}
	}
	&_trm,
	&_dsc {
		background-color: $siteCol-wht01;
	}
	header {
		padding: .5em 1em;
		color: $siteCol-brn04;
		font-size: calc(1.9em / 1.7);
		background-color: $siteCol-brn02;
    display: flex;
    align-items: baseline;
    h5{width: 50%; padding-right: 1em;}
    .items,
		.leader{
  		margin-top: 0!important;
  		font-size: .8em;   
		}
		.leader::before {
			content: "委員長：";
		}
		.btn.-acrd-btn{
  		margin-bottom: 0;
		}
	}


  main {
    border: 5px solid $siteCol-brn02;
  	padding: 1em;
  	* + ul,
  	* + ol {
  		margin-top: 0.5em;
  	}
  	.more {margin-bottom: 0;}
  }

}