// Extend styles
// =============================================================================
%extend {
	&--inner-box-1120 {
		@include mainCntWidth01(1120px);
	}
	&--inner-box-1200 {
		@include mainCntWidth01(1200px);
	}
	&--inner-box-1250 {
		@include mainCntWidth01(1250px);
	}
	&--inner-box-1320 {
		@include mainCntWidth01(1320px);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&--inner-box-1120,
		&--inner-box-1320 {
			padding: {
				right: $mbl-pad;
				left: $mbl-pad;
			}
		}
		&--inner-box-1250 {
			padding: {
				right: 12px;
				left: 12px;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&--inner-box-1120,
		&--inner-box-1320 {
			padding: {
				right: $pc-pad;
				left: $pc-pad;
			}
		}
		&--inner-box-1250 {
			padding: {
				right: 25px;
				left: 25px;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&--inner-box-1120,
		&--inner-box-1320 {
			padding: {
				right: $tblt-pad;
				left: $tblt-pad;
			}
		}
	}
}
