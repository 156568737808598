.mn-art {
  
	table {
		font-size: calc(.9em);
		line-height: 1.5;
		border: {
			radius: 5px;
			collapse: separate;
			spacing: 5px;
		}
		background-color: $siteCol-brn02;
	}
	* + table,
	* + .tbl-scrl, {
		margin-top: 1em;
	}
	th, td {
		padding: 0.5em;
		border-radius: 5px;
	}
	th {
		color: $siteCol-brn04;
		background-color: $siteCol-brn03;
	}
	td {
		//background-color: $siteCol-wht01;
		background-color: #fff;
	}
	.pg98 & {
		th, td {
			width: 5.5em !important;
			&:first-child {
				width: auto !important;
			}
			&:last-child {
				width: 3em !important;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.nbd {
			margin-top: 1.8em;
		}
	}
}

/*細かいテーブル*/
table.tbl_thin {
      background-color: $siteCol-brn04;
      font-size: calc(.7em);
      border-spacing: 1px; 
  th,td {border-radius: 0;}
}

/*開催日・開催地テーブル*/
table.tbl_date {
  th{width: 20%;}
  + ._lead {margin-top: 1.5em; margin-bottom: 1em; /* border-bottom: 1px solid $siteCol-brn02;  */}
}

.tbl-scrl {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	& > table {
		min-width: 730px;
	}
}