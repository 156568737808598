// Base styles: opinionated defaults
// =============================================================================
html {
	color: $siteCol-blk01;
	background-color: $siteCol-wht01;
}

body {
	// min-width: 1000px;
	line-height: 1.5;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		min-width: 320px;
		font-size: $fz15;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		font-size: $fz16;
	}
}

::-moz-selection {
	@include selectionStyle;
}
::selection {
	@include selectionStyle;
}

a {
	color: $siteCol-gly02;
	transition: color $overTime $easeOutQuint;
	&.is-actv {
		color: $siteCol-rsj-grn;
	}
	.mn-foot & {
		color: rgba($siteCol-f-wht, 0.5);
	}
	.mn-art &{
  	background-image: linear-gradient(transparent 70%, #d0e0ff 0%);
  	&.bt,&.bt2 {
    	background-image: none;
  	}
	}
	
	.mn-cnt_inr & {
  	color: $siteCol-d-blu02;
		font-weight: $fwNsnsJp-bold;
		transition: opacity $overTime $easeOutQuint;
		&[target="_blank"],
		&[href$=".pdf"],
		&.pdf,
		&[href$=".docx"] {
			&::after {
				padding: 0 .4em 0 .2em;
				font: {
					family: icomoon;
					size: calc(1.5em / 1.3);
					weight: $fwNsnsJp-medium;
				}
				line-height: 1;
				position: relative;
				//top: calc(0.3em / 1.5);
				top: 0.04em;
			}
		}
		&[target="_blank"] {
			&::after {
				content: '\e900';
			}
		}
		&[href$=".jpg"],&[href$=".gif"],&[href$=".png"] {
			&::after {
				content: none;
			}
		}
		&[href$=".pdf"],
		&.pdf {
			&::after {
				content: '\e902';
			}
		}
		&[href$=".docx"] {
			&::after {
				content: '\e904';
			}
		}
		.tmp9 & {
			&[target="_blank"] {
				&::after {
					content: '\e901';
				}
			}
			&[href$=".pdf"] {
				&::after {
					content: '\e903';
				}
			}
			&[href$=".docx"] {
				&::after {
					content: '\e905';
				}
			}
		}
		&.noicn {
			background-image: none;
			&::before,
			&::after {
				display: none;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&:hover {
			color: $siteCol-rsj-grn;
			.mn-foot & {
				color: $siteCol-pnk01;
			}
			.mn-cnt_inr & {
  			color: $siteCol-l-blu02;
				//color: $siteCol-pnk02;
				opacity: 0.4;
			}
		}
	}
}
