// General other styles
// =============================================================================
.lst-otr {
	// width: 100%;
	margin-left: calc(3em / 1.7);
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-end;
	& > * {
		&:nth-child(n+2) {
			margin-left: calc(3em / 1.3);
		}
	}
	.gen-foot & {
		width: 100%;
		margin-left: 0;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.gen-foot & {} 
		.gen-foot & {
			flex-direction: column;
			& > * {
				&:nth-child(n+2) {
					margin: calc(1.5em / 1.3) 0 0;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.gen-foot & {
			margin-bottom: calc(2.5em / 1.7);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		.gen-foot & {
			margin-bottom: 0;
			flex-direction: column;
			& > * {
				&:nth-child(n+2) {
					margin: calc(1.5em / 1.3) 0 0;
				}
			}
		}
	}
}

.lng-lst {
 	font: {
		size: calc(1.3em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.4;
	display: flex;
	align-items: center;
	&_itm {
		display: flex;
		align-items: center;
		&:nth-child(n+2) {
			&::before {
				content: '\e90c';
				margin: 0 0.6em;
				color: $siteCol-gly02 !important;
			 	font: {
					family: icomoon;
					weight: $fwNsnsJp-medium;
				}
				line-height: 1;
				display: block;
			}
		}
	}
	&_lnk {
		&.is-actv {
			color: $siteCol-rsj-grn;
			.gen-foot & {
				color: $siteCol-pnk01;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		font-size: 1.4 / 1.5 + 0em;
		&_lnk {
			.mn-head & {
				&::before {
					content: attr(data-lng);
				}
			}
		}
		&_txt-wrp {
			.mn-head & {
				&::before {
					content: attr(data-lng);
				}
			}
			& > span {
				.mn-head & {
					display: none;
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
}

.sns-lst {
	font-size: calc(1.3em / 1.7);
	line-height: 1;
	display: flex;
	align-items: center;
	.mn-art-head & {
		margin-top: 0.8em / 1.3 + 0em;
	}
	&_itm {
		margin-top: 0 !important;
		padding: 0 !important;
		flex-shrink: 0;
		&::before {
			display: none;
		}
		&:nth-child(2) {
			width: 108px;
		}
		&:nth-child(n+2) {
			margin-left: 1em;
		}
	}
}

.sub-lnk-lst {
 	font: {
		size: calc(1.3em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.4;
	display: flex;
	align-items: flex-start;
	&_itm {
		&:nth-child(n+2) {
			margin-left: calc(3em / 1.3);
		}
	}
	&_lnk {
		display: flex;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.mn-head & {
			display: none;
		}
		.gen-foot & {
			margin: calc(1.5em / 1.3) 0 0;
		} 
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		.gen-foot & {
			display: none;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.gen-foot & {
			margin-left: auto;
			order: 5;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		.mn-head & {
			display: none;
		}
		.gen-foot & {
			margin: calc(1.5em / 1.3) 0 0;
			order: 0;
		}
	}
}

.fb-share-button {
	a {
		&::after {
			display: none !important;
		}
	}
}

.gmap {
	width: 100%;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		height: 60vw;
		min-height: 260px;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		height: 450px;
	}
}
