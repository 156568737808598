@charset "utf-8";

@import "variables";

/* ==============================================================================================
   Reset styles
   ============================================================================================== */
@import "ress";

/* ==============================================================================================
   Base styles: opinionated defaults
   ============================================================================================== */
@import "opinion";

@import "icomoon";

/* ==============================================================================================
   Author's custom styles
   ============================================================================================== */

/* General styles
   ========================================================================== */
.id-num {
	padding: 0.5em;
	color: $siteCol-f-wht;
	font: {
		size: 1.3 / 1.6 +  0em;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1;
	background-color: $siteCol-pnk01;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 10000;
}

@import "gen-logo";

@import "gen-btn";

@import "gen-icn";

@import "gen-oth";

@import "ajax-search";

@import "extend";

/* Main header styles
   ========================================================================== */
@import "main-head";

/* Main navigation styles
   ========================================================================== */
@import "main-nav";

/* Main footer styles
   ========================================================================== */
@import "main-foot";

/* Main content styles
   ========================================================================== */
.mq-inf {
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		
	}
	@include mediaQueries-maxW('mbl-s') { // mobile size = S //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW('pc-m') { // desktop size = M //
		
	}
	@include mediaQueries-minW('pc-l') { // desktop size = L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-m', 'tblt-l') { // tablet size = M - L //
		
	}
}

.mn-cnt {
	padding: 2em 0 4em;


	&.-sub-cnt {
		padding-top: 1em;
	}


	&_inr {
		@extend %extend--inner-box-1320;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			.-sub-cnt & {
				display: flex;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			.-sub-cnt & {
				display: block;
			}
		}
	}
}

.mn-art {
	width: 100%;
	* + section,
	* + div.section {
		margin-top: calc(5em / 1.7);
	}
	&.mceContentBody{width: 100%;}
	.tmp30 & {width: 100%; margin-top: 2em;}
	h2, h3 {
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	h2 {
		margin-bottom: calc(3.4em / 2.5);
		padding: calc(0.8em / 2.5) 0;
		font-size: calc(2.5em / 1.7);
		border: {
			bottom: solid 3px $siteCol-rsj-grn;
			top-left-radius: calc(0.5em / 2.5);
		}
		overflow: hidden;
		&::before {
			height: 100%;
			background-color: $siteCol-rsj-grn;
			transform-origin: top right;
		}
	}
	* + h2 {
		margin-top: calc(5em / 2.5);
	}
	h3 {
		margin-bottom: calc(1.7em / 2.1);
		padding: calc(0.3em / 2.1) 0 calc(0.3em / 2.1) calc(1.5em / 2.1);
		color: $siteCol-rsj-grn;
		font-size: calc(2.1em / 1.7);
		&::before {
			width: 0;
			height: 0;
			border: solid calc(0.75em / 2.1) $siteCol-rsj-grn;
			border: {
				right: solid calc(0.75em / 2.1) transparent;
				bottom: solid calc(0.75em / 2.1) transparent;
				top-left-radius: calc(0.5em / 2.1);
			}
		}
	}
	h4 {
		margin-bottom: calc(1.7em / 1.9);
		color: $siteCol-rsj-grn;
		font-size: calc(1.9em / 1.7);
	}
	* + h3,
	* + h4 {
		margin-top: calc(3.4em / 2.1);
	}
	* + p {
		margin-top: 0.5em;
		.ua-mac-os & {
			text-align: left;
		}
	}
	.advpub-cpy {
		margin-top: 2em;
		color: $siteCol-rsj-grn;
		font-weight: $fwNsnsJp-bold;
	}
	ul, ol {
		counter-reset: counter-ol;
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			li {
				border-radius: calc(0.3em / 1.7);
				position: relative;
				&:nth-child(n+2) {
					margin-top: calc(0.8em / 1.7);
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
				}
				&[data-lst-styl] {
					&::before {
						content: attr(data-lst-styl);
					}
				}
			}
			& > li {
				ul, ol {
					margin-top: calc(0.8em / 1.7);
					position: relative;
				}
			}
		}
		&.tri {
			padding-left: 0;
			&::before {
				display: none;
			}
		}
	}
	* + ul,
	* + ol {
		margin-top: 0.5em;
	}
	ul {
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			& > li {
				$base-pdng-l: 1em;
				padding-left: $base-pdng-l;
				&::before {
					content: '・';
				}
			}
		}
	}
	ol {
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			& > li {
				$base-pdng-l: '3.6em / 1.7';
				padding-left: calc(#{$base-pdng-l});
				counter-increment: counter-ol;
				&::before {
					content: counter(counter-ol)'．';
					width: calc(#{$base-pdng-l});
					text-align: right;
				}
			}
		}
	}
	.tri {
		padding-left: 1em;
		position: relative;
		&,
		li {
			&::before {
				content: '\e907' !important;
				color: $siteCol-rsj-grn;
				font: {
					family: icomoon;
					size: 0.6em;
					weight: 500;
				}
				position: absolute;
				top: 0.55em !important;
				left: 0.3em !important;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		h2 {
			padding-left: calc(4em / 2.2);
			&::before {
				width: calc(3em / 2.2);
				transform: skew(-15deg, 0);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: calc(100% - 300px);
		padding-right: $tblt-pad;
		h2 {
			padding-left: calc(7.5em / 2.5);
			&::before {
				width: calc(6.5em / 2.5);
				transform: skew(-30deg, 0);
			}
		}
		.advpub-cpy {
			font-size: 1.1em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		width: calc(100% - 270px);
		padding-right: $mbl-pad;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		width: 100%;
		padding-right: 0;
	}
}

.mn-art-head {
	margin-bottom: 1.5em;
	color: $siteCol-gly02;
	font-weight: $fwNsnsJp-bold;
	&_ttl {
		color: $siteCol-rsj-grn;
		font-size: 1.6em;
	}
	&_nts {
		margin-top: calc(0.5em / 1.5) !important;
		font-size: calc(1.5em / 1.7);
		line-height: 1.5;
		display: flex;
		&::before {
			content: '※';
		}
	}
}

.pagenate {
	margin-top: 4em;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	&_itm,
	&_prv,
	&_nxt {
		margin: 3px 2px;
		& > * {
			height: 2.5em;
			min-width: 2.5em;
			padding: 0.5em;
			background-color: $siteCol-gly06;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	a {
		color: $siteCol-f-wht;
		background: {
			color: $siteCol-rsj-grn;
			image: none;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		font-size: 1.3 / 1.5 + 0em;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		a {
			&:hover {
				color: $siteCol-f-wht;
			}
		}
	}
}

.prev_next {
	// margin-top: 3em !important;
}

/* Breadcrumbs list styles
   ======================================== */
@import "breadcrumbs-list";

/* Contents side bar styles
   ======================================== */
@import "cnt-side";

/* Tab menu styles
   ======================================== */
@import "tab-menu";

/* Top page styles
   ======================================== */
@import "cnt-top";

/* Sub top styles
   ======================================== */
@import "cnt-sub-top";

/* List item styles
   ======================================== */
@import "lst_itm";

/* FAQ styles
   ======================================== */
@import "faq";

/* Committees styles
   ======================================== */
@import "rbgk-tm-line";

/* Robogaku timeline styles
   ======================================== */
@import "committees";

/* 403 styles
   ======================================== */
@import "403";

/* Modal styles
   ======================================== */
@import "mdl";

/* ==============================================================================================
   Utility classes
   ============================================================================================== */
@import "utility-gen";

@import "utility-rsj";

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@import "print";

/* ==============================================================================================
   Other styles
   ============================================================================================== */

@import  "4_list";
@import  "5_table";
@import  "form";
@import  "template";
@import  "page";
@import  "deco";
