@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?sjb745') format('truetype'),
    url('../fonts/icomoon.woff?sjb745') format('woff'),
    url('../fonts/icomoon.svg?sjb745#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icn-"], [class*=" icn-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &[target="_blank"] {
    &::after {
      display: none;
    }
  }
}

.icn-usr-sld:before {
  content: "\e90f";
}
.icn-usr:before {
  content: "\e910";
}
.icn-home-sld:before {
  content: "\e90d";
}
.icn-home:before {
  content: "\e90e";
}
.icn-splt:before {
  content: "\e90c";
}
.icn-txt-as:before {
  content: "\e90a";
}
.icn-txt-qs:before {
  content: "\e90b";
}
.icn-lnk-arw:before {
  content: "\e909";
}
.icn-lnk-m:before {
  content: "\e908";
}
.icn-lnk:before {
  content: "\e906";
}
.icn-lst-arw:before {
  content: "\e907";
}
.icn-blnk-sld:before {
  content: "\e900";
}
.icn-blnk:before {
  content: "\e901";
}
.icn-pdf-sld:before {
  content: "\e902";
}
.icn-pdf:before {
  content: "\e903";
}
.icn-wrd-sld:before {
  content: "\e904";
}
.icn-wrd:before {
  content: "\e905";
}
