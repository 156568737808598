// Main navigation styles
// =============================================================================
.mn-nav {
	line-height: 1.5;
	&_inr {
		display: flex;
		align-items: center;
		&.-prm-inr {
			padding: calc(1em / 1.7) 0;
			background-color: $siteCol-brn01;
			justify-content: center;
		}
		&.-scnd-inr {
			@extend %extend--inner-box-1250;
			padding: {
				top: calc(2em / 1.7);
				bottom: calc(2em / 1.7);
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			&.-prm-inr {
				padding: 0;
				display: none;
			}
			&.-scnd-inr {
				padding: {
					top: 2px;
					bottom: 6px;
				}
				flex-wrap: wrap;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: calc(1.5em / 1.7);
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			&.-scnd-inr {
				padding: {
					top: 16px;
					bottom: 8px;
				}
				flex-wrap: wrap;
			}
		}
	}
}

.lgn-otr {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		width: 100%;
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		// margin-top: 0.3em;
		// justify-content: center;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		margin-left: 20px;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		width: 100%;
		margin-left: 0;
	}
}

.usr-name {
	color: $siteCol-gly03;
	font: {
		size: 1.5 / 1.6 + 0em;
		weight: $fwNsnsJp-bold;
	}
	display: flex;
	align-items: baseline;
	&::before {
		content: '\e90f';
		margin-right: 0.2em;
		color: $siteCol-rsj-grn;
		font: {
			family: icomoon;
			size: 1.7 / 1.5 + 0em;
			weight: $fwNsrfJp-medium;
		}
		line-height: 1;
		position: relative;
		top: 0.1em;
	}
	&_lnk {
		color: $siteCol-gly03 !important;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin: 6px auto 6px 0;
		font-size: 1.3 / 1.5 + 0em;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_lnk {
			&:hover {
				color: $siteCol-rsj-grn !important;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin: 8px auto 8px 0;
	}
}

/* Fixed navigation styles
   ======================================== */
.fxd-nav-otr {
	width: 100%;
	height: 100%;
	padding: calc(4em / 1.7) 0 calc(6em / 1.7);
	opacity: 0;
	visibility: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9000;
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	&.is-shw {
		opacity: 1;
		visibility: visible;
	}
	& > * {
		position: relative;
		z-index: 10;
	}
}

.fxd-nav {
	width: 90%;
	padding: {
		top: calc(3em / 1.7);
	}
	border-radius: 1 / 1.7 + 0em;
	background-color: $siteCol-f-wht;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	opacity: 0;
	visibility: hidden;
	transform: scale(0.75);
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	&::-webkit-scrollbar {
		display: none;
	}
	&::after {
		content: '';
		width: 100%;
		padding-top: calc(6em / 1.7);
		display: block;
	}
	.is-shw & {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
		transition:
			opacity $navShwTime $navShwTime / 2 $easeOutQuint,
			visibility $navShwTime $navShwTime / 2 $easeOutQuint,
			transform $navShwTime $navShwTime / 2 $easeOutQuint;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: {
			right: $mbl-pad;
			left: $mbl-pad;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: {
			right: $pc-pad;
			left: $pc-pad;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding: {
			right: $tblt-pad;
			left: $tblt-pad;
		}
	}
}

.overlay {
	width: 100%;
	height: 100%;
	background-color: rgba($siteCol-k-blk, 0.75);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
}

/* Navigation list styles
   ======================================== */
.nav-lst-otr {
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: calc(0.8em / 1.5) 0;
	}
}

.nav-lst {
	font-weight: $fwNsnsJp-bold;
	display: flex;
	align-items: center;
	&_itm {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		&:nth-last-child(n+2) {
			&::after {
				content: '';
				width: $icn-splt-nav-size;
				height: $icn-splt-nav-size;
				margin: 0 calc(3em / 1.7);
				background: {
					image: imgUrl('img_icn_splt.svg');
					repeat: no-repeat;
					position: center;
					size: $icn-splt-nav-size $icn-splt-nav-size;
				}
				display: block;
				transform: rotate(15deg);
			}
		}
	}
	&_lnk {
		color: $siteCol-gly03;
		.is-actv > & {
			color: $siteCol-rsj-grn;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:first-child {
				padding-left: $mbl-pad;
			}
			&:last-child {
				padding-right: $mbl-pad;
			}
			&:nth-last-child(n+2) {
				&::after {
					margin: 0 calc(1.5em / 1.5);
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			&:nth-last-child(n+2) {
				&::after {
					margin: 0 calc(1.8em / 1.5);
				}
			}
		}
	}
}

/* Pickup list styles
   ======================================== */
.pu-lst {
	margin-left: calc(2.4em / 1.5);
 	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	&_itm {
		&:nth-child(n+2) {
			margin-left: calc(2.4em / 1.5);
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		display: none;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		display: none;
	}
}

/* Contents list styles
   ======================================== */
.cnt-lst {
	@extend %extend--inner-box-1200;
 	font: {
		size: 1.5 / 1.6 + 0em;
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	&.-ch-lst {
		max-width: none;
		margin-right: -2 / 1.3 + 0em;
		padding: 0 0 0 2 / 1.3 + 0em;
		font-size: 1.3 / 1.5 + 0em;
		display: flex;
		flex-wrap: wrap;
	}
	.fxd-nav & {
		&:not(.-ch-lst) {
			margin-top: 3 / 1.5 + 0em;
		}
	}
	.foot-nav & {
		@extend %extend--inner-box-1320;
	}
	&_lnk {
		.-lv01 > & {
			color: $siteCol-gly03;
			line-height: 1.4;
			display: inline-flex;
			.foot-nav & {
				color: $siteCol-f-wht;
			}
		}
		.is-actv >  & {
			color: $siteCol-rsj-grn;
			.foot-nav & {
				color: $siteCol-pnk01;
			}
		}
	}
	&_itm {
		&.-lv01 {
			&:nth-child(n+2) {
				margin-top: 2.5 / 1.5 + 0em;
			}
		}
		.-ch-lst & {
			margin: 1 / 1.3 + 0em 2 / 1.3 + 0em 0 0;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-ch-lst {
			.foot-nav & {
				margin: 1.2 / 1.3 + 0em 0 0;
				display: block;
			}
		}
		.foot-nav & {
			display: flex;
			flex-wrap: wrap;
		}
		&_itm {
			&.-lv01 {
				.foot-nav & {
					width: calc((100% - 20px * 4) / 5);
					&:nth-child(n+2) {
						margin: 0 0 0 20px;
					}
					&:nth-child(n+6) {
						margin-top: 40px;
					}
					&:nth-child(5n+6) {
						margin-left: 0;
					}
				}
			}
			.-ch-lst & {
				.foot-nav & {
					margin: 0;
					&:nth-child(n+2) {
						margin-top: 0.6 / 1.3 + 0em;
					}
				}
			}
		}
		&_lnk {
			.-lv01 > & {
				&:hover {
					color: $siteCol-rsj-grn;
					.foot-nav & {
						color: $siteCol-pnk01;
					}
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			&.-lv01 {
				.foot-nav & {
					width: calc((100% - 20px * 2) / 3);
					&:nth-child(n+2) {
						margin: 0 0 0 20px;
					}
					&:nth-child(n+4) {
						margin-top: 40px;
					}
					&:nth-child(3n+4) {
						margin-left: 0;
					}
					.ua-ie & {
						width: calc(33.33% - 20px);
					}
				}
			}
		}
	}
}
