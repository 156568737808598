// 403 styles
// =============================================================================
body {
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.p-403 {
			font-size: $fz14;
		}
	}
}

.mn-cnt {
	.p-403 & {
		padding: 0;
		overflow: hidden;
	}
}

.art-403 {
	line-height: 1.7;
	text-align: justify;
	&_bdy {
		max-width: 100 / 1.6 + 0em;
		margin: 0 auto;
		position: relative;
	}
	&_inr {
		max-width: 50 / 1.6 + 0em;
		position: relative;
		z-index: 10;
	}
	&_intr {
		margin-top: 3 / 2 + 0em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding: 4 / 1.4 + 0em 2 / 1.4 + 0em;
		&_inr {
			margin: 0 auto;
		}
		&_intr {
			font-size: 1.6 / 1.4 + 0em;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding: 8 / 1.6 + 0em 4 / 1.6 + 0em;
		&_bdy {
			&::after {
				content: '';
				width: 554 / 980 * 100vw;
				height: 570 / 980 * 100vw;
				max: {
					width: 55.4 / 1.6 + 0em;
					height: 57.0 / 1.6 + 0em;
				}
				background: {
					image: imgUrl('img_403.svg');
					repeat: no-repeat;
					position: center;
					size: 100% 100%;
				}
				display: block;
				position: absolute;
				// top: calc(#{$baseCenterPos} - 15px);
				bottom: 0.5%;
				left: 45%;
				z-index: 5;
				// transform: translate3d(-#{$baseCenterPos}, 0, 0);
			}
		}
		&_inr {
			width: 500 / 960 * 100%;
		}
		&_intr {
			font-size: 2 / 1.6 + 0em;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		padding-top: 80 / 1050 * 100vw;
		&_intr {
			font-size: calc(20 / #{1050 - 40 * 2} * (100vw - #{40 * 2}px));
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_bdy {
			&::after {
				display: none;
			}
		}
		&_inr {
			width: 100%;
			margin: 0 auto;
		}
		&_intr {
			font-size: 2 / 1.6 + 0em;
		}
	}
}

.sct-en-403 {
	margin-top: 2 / 1.6 + 0em;
	color: $siteCol-gly02;
	font-weight: $fwNsnsJp-bold;
	line-height: 1.5;
	&_ttl {
		color: $siteCol-rsj-grn;
		font-weight: $fwNsnsJp-black;
	}
	text-align: left;
	&_cpy {
		margin-top: 1 / 1.6 + 0em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_ttl {
			text-align: center;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: calc(16 / #{1050 - 40 * 2} * (100vw - #{40 * 2}px));
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: inherit;
		&_ttl {
			text-align: center;
		}
	}
}