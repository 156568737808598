// Top page styles
// =============================================================================
.cnt-top-lst {
	color: $siteCol-f-wht;
	font-weight: $fwNsnsJp-bold;
	text-align: left;
	line-height: 1.5;
	display: flex;
	gap: 4px;
	flex-wrap: wrap;
	&_itm {
		padding: 2.3 / 1.6 + 0em 2.8 / 1.6 + 0em 3.2 / 1.6 + 0em;
		background-color: $siteCol-gly03;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 0;
		$itmBgColArr: (
			1: #11aeae,
			2: $siteCol-k-blk,
			3: #002c4a,
			4: #ffa200,
			5: $siteCol-k-blk,
			6: #e4451a,
			7: #5f4b8b,
			8: $siteCol-rsj-grn,
			9: #b50b57,
			10: $siteCol-k-blk,
			11: #259ae1,
			12: #e41a2d,
		);
		@each $itmNum, $itmBgCol in $itmBgColArr {
			&:nth-child(#{$itmNum}) {
				background-color: $itmBgCol;
			}
		}
	}
	&_trm {
		margin-bottom: 0.5em;
		font-size: 2 / 1.6 + 0em;
		line-height: 1.4;
	}
	&_dsc {
		margin-top: auto;
		font-size: 1.3 / 1.6 + 0em;
	}
	&_lnk {
		color: inherit !important;
		opacity: 1 !important;
		display: flex;
		flex-direction: column;
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	&_lnk-txt,
	&_lst-upd,
	&_dt {
		display: flex;
		align-items: center;
	}
	&_lnk-txt {
		&::after {
			content: '\e909';
			margin-left: 0.5em;
			font: {
				family: icomoon;
				size: 1.7 / 1.3 + 0em;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
		}
	}
	&_lst-upd {
		margin-top: 0.3 / 1.3 + 0em;
	}
	&_dt {
		// margin-left: calc(2.1em / 1.3);
		position: relative;
		&::before {
			content: '\e90c';
			margin: 0 0.4em;
			font: {
				family: icomoon;
				weight: $fwNsrfJp-medium;
			}
			line-height: 1;
		}
	}
	&_thmb {
		width: 100%;
		height: 100%;
		opacity: 0.5;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		& img {
			width: 100%;
			height: 100%;
			object: {
				fit: cover;
				position: center 25%;
			}
			font-family: 'object-fit: cover; object-position: center 25%;';
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			width: calc((100% - 4px) / 2);
			padding: 1em 1.8 / 1.5 + 0em 2 / 1.5 + 0em;
			&:nth-child(even) {
				// margin-left: 4px;
			}
			&:nth-child(n+3) {
				// margin-top: 4px;
			}
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_itm {
			width: 100%;
			&:nth-child(even) {
				// margin-left: 0;
			}
			&:nth-child(n+2) {
				// margin-top: 4px;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			width: calc((100% - 4px * 3) / 4);
			min-height: 18 / 1.6 + 0em;
			&:nth-child(n+2) {
				// margin-left: 4px;
			}
			&:nth-child(n+5) {
				// margin-top: 4px;
			}
			&:nth-child(4n+1) {
				// margin-left: 0;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			width: calc((100% - 4px * 2) / 3);
			// margin: 0;
			&:nth-child(n+2) {
				// margin-left: 4px;
			}
			&:nth-child(n+4) {
				// margin-top: 4px;
			}
			&:nth-child(3n+1) {
				// margin-left: 0;
			}
			.ua-ie & {
				width: calc(33.33% - 4px);
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: $fz15;
	}
}

.cnt-bnr-lst {
	margin-top: calc(2em / 1.7);
	color: $siteCol-f-wht;
	&_itm {
		padding: calc(5em / 1.7) calc(6em / 1.7);
		background: {
			color: $siteCol-blk01;
			image: imgUrl('img_bg_bnr_dummy.jpg');
			repeat: no-repeat;
			position: right center;
			size: cover;
		}
		&,
		& > * {
			position: relative;
		}
		& > * {
			z-index: 15;
		}
		&:nth-child(n+2) {
			margin-top: calc(2em / 1.7);
		}
		&::before,
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
		&::before {
			background: {
				color: rgba($siteCol-k-blk, 0.1);
				image: imgUrl('img_bg_dot.svg');
				position: left top;
				size: 4px 4px;
			}
			opacity: 0.75;
			z-index: 10;
		}
		&::after {
			background: {
				image: linear-gradient(
					90deg,
					rgba($siteCol-bnr-grd01, 1) 0%,
					rgba($siteCol-bnr-grd02, 1) 25%,
					rgba($siteCol-bnr-grd03, 0.65) 70%,
					rgba($siteCol-bnr-grd04, 0.3) 100%
				);
			}
			opacity: 0.9;
			z-index: 5;
		}
	}
	&_trm {
		font: {
			size: calc(2.9em / 1.7);
			weight: $fwNsnsJp-medium;
		}
		line-height: 1.4;
	}
	&_dsc {
		margin-top: calc(2.5em / 1.7);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			padding: calc(2.4em / 1.5) calc(2.2em / 1.5) calc(3em / 1.5);
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		&_itm {
			&::after {
				background: {
					image: linear-gradient(
						135deg,
						rgba($siteCol-bnr-grd01, 1) 0%,
						rgba($siteCol-bnr-grd02, 1) 25%,
						rgba($siteCol-bnr-grd03, 0.65) 70%,
						rgba($siteCol-bnr-grd04, 0.3) 100%
					);
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		font-size: $fz15;
	}
}
