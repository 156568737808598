// Main header styles
// =============================================================================
.mn-head {
	border-top: solid 3px $siteCol-rsj-grn;
	background-color: $siteCol-f-wht;
	&_inr {
		@extend %extend--inner-box-1250;
		padding: {
			top: calc(1em / 1.7);
			bottom: calc(1em / 1.7);
		}
		display: flex;
		// flex-wrap: wrap;
		align-items: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_inr {
			padding: 8px 12px;
		}
	}
}
