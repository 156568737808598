.mn-cnt {
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_inr {
			.pg1 & {
				font-size: clamp(1.4rem, #{16 / 1240 * 100vw}, 1.6rem);
				display: flex;
				gap: 3 / 1.6 + 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_inr {
			.pg1 & {
				gap: 2 / 1.4 + 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_inr {
			.pg1 & {
				font-size: inherit;
				display: block;
			}
		}
	}
}

.cnt-top-lst {
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			width: calc((100% - 4px * 2) / 3);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			width: calc((100% - 4px) / 2);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_itm {
			width: calc((100% - 4px * 2) / 3);
		}
	}
}

.rbgk-tm-line {
	--inrVgap-xAxs: #{1.5 / 1.6 + 0em};
	margin-top: var(--otrVgap-xAxs);
	border: 2px solid rgba($siteCol-blk01, 0.1);
	border-radius: 1.5 / 1.6 + 0em;
	background-color: $siteCol-wht02;
	overflow: hidden;
	&_hdr {
		padding: {
			block: 2 / 1.6 + 0em;
			inline: var(--inrVgap-xAxs);
		}
		box-shadow: 0 1.2em 1.2em -1.4em rgba($siteCol-k-blk, 0.1);
		background-color: $siteCol-f-wht;
		display: flex;
		gap: 1em;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 25;
	}
	&_bdy {
		height: 100%;
		background-color: inherit;
		position: relative;
		z-index: 0;
	}
	&_ttl {
		color: $siteCol-rbgk-blu;
		display: flex;
		align-items: flex-end;
		&-txt {
			font-size: 2 / 1.6 + 0em;
			display: block;
			&.-lrg {
				// width: 10 / 1.6 + 0em;
				width: 12 / 2 + 0em;
				margin-right: 0.4em;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		--otrVgap-xAxs: #{3 / 1.5 + 0em};
		font-size: clamp(1.4rem, #{14 / 320 * 100vw}, 1.5rem);
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: 100%;
		// max-width: 36 / 1.6 + 0em;
		max-width: 29 / 1.6 + 0em;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		&_ttl {
			&-txt {
				font-size: 1.8 / 1.6 + 0em;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		font-size: clamp(1.2rem, #{14 / 1050 * 100vw}, 1.4rem);
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		--otrVgap-xAxs: #{4 / 1.6 + 0em};
		max-width: none;
		font-size: inherit;
		&_ttl {
			&-txt {
				font-size: 2 / 1.6 + 0em;
			}
		}
	}
}

.tm-line-lst {
	width: 100%;
	max-height: var(--lst-maxHgt);
	padding: {
		block: 3 / 1.6 + 0em;
		inline: var(--inrVgap-xAxs);
	}
	display: flex;
	gap: 2.5 / 1.6 + 0em var(--inrVgap-xAxs);
	flex-wrap: wrap;
	overflow: scroll;
	&_lnk {
		color: inherit !important;
		&::after {
			display: none;
		}
	}
	&_ttl {
		color: inherit;
		font-size: 1.4 / 1.6 + 0em;
		margin-top: 0.75em;
		&-txt {
			display: block;
			&.-dt {
				font-size: 1.2 / 1.4 + 0em;
			}
			& + & {
				margin-top: 0.25em;
			}
		}
	}
	&_fig {
		// height: 14 / 1.6 + 0em;
		// aspect-ratio: 128 / 65;
		aspect-ratio: 65 / 28;
		color: $siteCol-rbgk-blu;
		border-radius: 0.3 / 1.6 + 0em;
		overflow: hidden;
		box-shadow: 0 1.2em 1.2em -1.4em rgba($siteCol-k-blk, 0.25);
		background-color: $siteCol-f-wht;
		position: relative;
		&-img {
			display: block;
			position: relative;
			z-index: 10;
			&.-thmb {
				height: 100%;
				& > * {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&.-rbgk {
				width: 210 / 326 * 100%;
				aspect-ratio: 163 / 53;
				position: absolute;
				top: $baseCenterPos;
				left: $baseCenterPos;
				z-index: 0;
				transform: translate3d(-#{$baseCenterPos}, -#{$baseCenterPos}, 0);
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		--lst-maxHgt: #{32 / 1.5 + 0em};
		font-size: clamp(1.2rem, #{12 / 425 * 100vw}, 1.5rem);
		&_itm {
			width: calc((100% - var(--inrVgap-xAxs)) / 2);
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		font-size: inherit;
		&_itm {
			width: 100%;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		height: 100%;
		position: absolute;
		&_itm {
			width: 100%;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		--lst-maxHgt: #{36 / 1.6 + 0em};
		position: static;
		&_itm {
			width: calc((100% - var(--inrVgap-xAxs)) / 2);
		}
	}
}