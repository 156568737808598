// Breadcrumbs list styles
// =============================================================================
.bc-lst-otr {
	@extend %extend--inner-box-1320;
	padding: 0;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	& + * {
		margin-top: calc(2em / 1.7);
	}
}

.bc-lst {
	height: calc(4em / 1.3);
	color: $siteCol-gly02;
 	font: {
		size: calc(1.3em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.4;
	display: flex;
	align-items: center;
	&_itm {
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_itm {
			&:first-child {
				padding-left: $mbl-pad;
			}
			&:last-child {
				padding-right: $mbl-pad;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			&:first-child {
				padding-left: $pc-pad;
			}
			&:last-child {
				padding-right: $pc-pad;
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		&_itm {
			&:first-child {
				padding-left: $tblt-pad;
			}
			&:last-child {
				padding-right: $tblt-pad;
			}
		}
	}
}