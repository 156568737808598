// Main footer styles
// =============================================================================
.mn-foot {
	
}

/* Sponsor styles
   ======================================== */
.spns {
	background-color: $siteCol-brn02;
	&_inr {
		@extend %extend--inner-box-1320;
		padding: {
			top: 3em;
			bottom: 3em;
		}
	}
}

.spns-lst {
	color: $siteCol-brn04;
	&_itm {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		&:nth-child(n+2) {
			margin-top: calc(4.5em / 1.7);
		}
	}
	&_trm {
		width: 100%;
		margin-bottom: calc(.3em);
		//font-size: calc(2.5em / 1.7);
		text-align: center;
		line-height: 1.4;
	}
	&_lnk {
		border: solid 2px $siteCol-brn05;
		display: block;
		background-color: #fff;
		text-align: center;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_dsc {
			width: calc((100% - 1em / 1.5) / 2);
			max-width: calc(23.5em / 1.5);
			&:nth-child(odd) {
				margin-left: calc(1em / 1.5);
			}
			&:nth-child(n+4) {
				margin-top: calc(1em / 1.5);
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_itm {
			margin: 0 calc(-1em / 1.7);
		}
		&_dsc {
			width: calc(23.5em / 1.7);
			margin: calc(1em / 1.7);
		}
	}
}

/* Footer navigation styles
   ======================================== */
.foot-nav {
	padding: {
		top: calc(8em / 1.7);
		bottom: calc(8em / 1.7);
	}
	@include bgGradOpt($baseCol: $siteCol-blk02, $stratCol: $siteCol-blk02, $endCol: $siteCol-blk03, $deg: -45deg);
}

/* General footer styles
   ======================================== */
.gen-foot {
	color: $siteCol-f-wht;
	@include bgGradOpt($baseCol: $siteCol-d-grn03, $stratCol: $siteCol-d-grn03, $endCol: $siteCol-d-grn02, $deg: -45deg);
	&_inr {
		@extend %extend--inner-box-1320;
		padding: {
			top: calc(8em / 1.7);
			bottom: calc(12em / 1.7);
		}
	}
	&_flx {
		margin-top: calc(2.5em / 1.7);
		display: flex;
		flex-wrap: wrap;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_flx {
			flex-direction: column;
			align-items: center;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&_flx {
			flex-direction: column;
			align-items: center;
		}
	}
}

.addr-dtl {
	margin-top: calc(2.5em / 1.7);
	&_trm {
		font-size: calc(1.5em / 1.7);
	}
	&_dsc {
		margin-top: calc(1em / 1.3);
		font-size: calc(1.3em / 1.7);
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		text-align: center;
		&_itm {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		text-align: center;
		&_itm {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

.addr-tel {
	display: flex;
	align-items: center;
	&_itm {
		position: relative;
		&:nth-child(n+2) {
			margin-left: calc(3.1em / 1.3);
		}
	}
	&_lnk {
		color: $siteCol-f-wht !important; 
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		justify-content: center;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		justify-content: center;
	}
}

.cr-txt {
	font: {
		size: 1.1 / 1.6 + 0em;
		weight: $fwNsnsJp-black;
	}
	line-height: 1.5;
	text-align: left;
	.gen-foot & {
		font-weight: $fwNsnsJp-medium;
	}
	.p-403 & {
		margin-top: 6 / 1.1 + 0em;
	}
	&_lnk {
		color: $siteCol-f-wht !important;
		.p-403 & {
			color: $siteCol-rsj-grn !important;
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: calc(2.5em / 1.1);
		text-align: center;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		order: 0;
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		margin-top: calc(2.5em / 1.1);
		text-align: center;
		order: 5;
		.p-403 & {
			// text-align: left;
		}
	}
}
