// List item styles
// =============================================================================
.list_box {
	.tmp28 &,
	.tmp36 & {
		display: flex;
		flex-wrap: wrap;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		
	}
}
.list_item {
	padding: calc(3em / 1.7) 0;
	line-height: 1.5;
	&:first-child {
		padding-top: 0 !important;
	}
	&:last-child {
		padding-bottom: 0 !important;
	}
	&:nth-child(n+2) {
		margin-top: 0;
		border-top: solid 2px $siteCol-gly05;
	}
	.tmp28 &,
	.tmp36 & {
		border-top: 0;
	}
	/*tmp9クラスは不要。後で削除する！*/
	.tmp2 &,
	.tmp7 &,
	.tmp9 &,
	.tmp35 &,
	.tmp26 &,
	.tmp27 & {
		padding: calc(5em / 1.7) 0;
	}
	.tmp9 &,
	.tmp35 & {
		display: flex;
		align-items: center;
		& > div {
			width: 100%;
		}
	}
	.tmp28 &,
	.tmp36 &  {
		padding: 0;
	}
	h2 {
		margin-bottom: 0;
		padding: 0;
		font-size: calc(1.1em);
		border-bottom: 0;
		border-radius: 0;
		&::before {
			display: none;
		}
		
		.tmp1 &,
		.tmp4 &,
		.tmp32 &,
		.tmp37 &,
		.tmp24 & {
			width: calc( 100% - 10em );
		}
		/*tmp27はあとで削除*/
		.tmp2 &,
		.tmp7 &,
		.tmp26 &,
		.tmp27 & {
			width: auto;
			margin-top: 0;
			padding-right: 0;
			font-size: calc(2.5em / 1.7);
		}
		.tmp9 &,
		.tmp35 & {
			width: auto;
			padding-right: 0;
			display: flex;
			flex-direction: column;
			.lng-ttl {
				margin-top: calc(1em / 2.3);
				font-size: calc(2.3em / 1.9);
			}
		}
		.tmp28 &,
		.tmp36 & {
			width: auto;
			margin-bottom: 1em;
			padding-right: 0;
			font-size: 1em;
			text-align: center;
		}
	}
	time {
		margin-bottom: calc(0.5em / 1.5);
		font: {
			size: .9em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.4;
		display: block;
		.tmp2 &,
		.tmp7 &,
		.tmp26 &,
		.tmp27 & {
			margin-bottom: calc(0.3em / 1.5);
		}
	}
	._lead {
		margin-top: 1em;
		font-size: calc(1.5em / 1.7);
	}
	& > ul {
		.tmp2 &,
		.tmp7 &,
		.tmp26 &,
		.tmp27 & {
			line-height: 1.7;
		}
		.tmp2 &,
		.tmp26 &,
		.tmp27 & {
			margin-top: calc(3.4em / 1.7);
		}
		.tmp7 & {
			margin-top: calc(2.5em / 1.7)
		}
		li {
			.tmp2 &,
			.tmp7 &,
			.tmp26 &,
			.tmp27 & {
				&:nth-child(n+2) {
					margin-top: calc(2.5em / 1.7) !important;
				}
			}
		}
	}
	figure {
		img {
			width: 100%;
		}
		.tmp9 &,
		.tmp35 & {
			width: 100%;
			max-width: calc(24.4em / 1.7);
			border: solid 2px $siteCol-gly06;
			flex-shrink: 0;
		}
		.tmp28 &,
		.tmp36 & {
			padding: 0 1em;
		}
	}
	.bt, .bt2 {
		margin-bottom: 0;
	}
	.more {
		justify-content: center;
		.tmp2 &,
		.tmp26 &,
		.tmp27 & {
			width: auto;
			margin-top: calc(1.4em / 1.7);
			justify-content: flex-start;
		}
		a {
			.tmp2 &,
			.tmp26 &,
			.tmp27 & {
				padding: calc(0.25em / 1.3) 1em;
				font-size: $fz13;
				align-items: center;
			}
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		.tmp28 &,
		.tmp36 &{
			width: calc((100% - #{$mbl-pad}) / 2);
			margin: 0;
			&:nth-child(even) {
				margin-left: $mbl-pad;
			}
			&:nth-child(n+3) {
				margin-top: $tblt-pad;
			}
		}
		h2 {
			.tmp32 &,
			.tmp37 &,
			.tmp24 & {
				width: auto;
			}
		}
		figure {
			margin: calc(2em / 1.5) auto 0;
			.tmp28 &,
			.tmp36 & {
				margin: 0;
			}
		}
		.more {
			max-width: calc(28em / 1.5);
			margin: calc(2em / 1.5) auto 0;
			.tmp2 &,
			.tmp26 &,
			.tmp27 & {
				max-width: none;
				margin: {
					right: 0;
					left: 0;
				}
			}
			a {
				width: 100%;
				padding: calc(1em / 1.3) 1em;
				.tmp2 &,
				.tmp26 &,
				.tmp27 & {
					width: auto;
					span {
						display: none;
					}
				}
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		.tmp9 &,
		.tmp35 & {
			& > div {
				padding-left: $tblt-pad;
			}
		}
		.tmp28 &,
		.tmp36 & {
			border-top: none;
			width: calc((100% - #{$tblt-pad} * 2) / 3);
			&:nth-child(n+2) {
				margin-left: $tblt-pad;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			&:nth-child(n+4) {
				margin-top: #{$pc-pad - 10};
			}
		}
		.tmp28.ua-ie &,
		.tmp36.ua-ie & {
			width: calc(33.3% - #{$tblt-pad};
		}
		time + div {
			display: flex; 
			flex-wrap: wrap;
			align-items: center;
		}
		h2 { 
			//width: calc(100% - 16.3em / 1.9);
			//padding-right: calc(2em / 1.9);
			order: 0;
			.tmp32 &,
			.tmp37 &,
			.tmp24 & {
				margin-right: 0.5em;
			}
/*
			.tmp15 & {
				width: 100%;
				padding-right: 0;
			}
*/
		}
		._lead {
			width: 100%;
			order: 10;
		}
		.more {
			width: 10em;
			margin: 0;
			order: 5;
			a {
				padding: calc(0.3em / 1.5) 1em;
				&::after {
					margin-left: calc(2.4em / 1.6);
				}
			}
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-m', 'tblt-l') { // tablet size = M - L //
		.tmp9 &,
		.tmp35 & {
			& > div {
				padding-left: $mbl-pad;
			}
		}
		.tmp28 &,
		.tmp36 & {
			width: calc((100% - #{$tblt-pad}) / 2) !important;
			margin: 0;
			&:nth-child(n+2) {
				margin-left: 0;
			}
			&:nth-child(even) {
				margin-left: $tblt-pad - 10;
			}
			&:nth-child(n+3) {
				margin-top: #{$pc-pad - 10};
			}
		}
		figure {
  		.tmp9 &,
			.tmp35 & {
				max-width: calc(18em / 1.7);
			}
		}
	}
}