//  Decoration styles
// =============================================================================
.cnt-acrd {
	display: none;
}

.col2 {
  flex-wrap: wrap;
  justify-content: center;
  
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		& > * {
/*
			&:nth-child(n+2) {
				margin-top: 1em;
			}
*/
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: flex;
		& > * {
			//width: calc((100% - #{$mbl-pad}) / 2);
			margin-right: 1%;
			margin-left: 1%;
			width: 48%;
/*
			&:nth-child(n+2) {
				margin-left: $mbl-pad;
			}
*/
		}
	}
	* + & {
		margin-top: 1em;
	}
}

.mn-art {
  .frame {
    background-color: $siteCol-f-wht; padding: 1em;
    border: 2px solid $siteCol-gly06;
    &._red{ border: 2px solid $siteCol-pnk02; }
    
    h3,h4 {
    padding-left: 0;
    padding-bottom: .3em;
    border-bottom: solid 1px $siteCol-gly06;
    }
    
    h3::before {
      width: 0;
      height: 0;
      border: none;
      border-right: solid calc(0.75em / 2.1) transparent;
      border-bottom: solid calc(0.75em / 2.1) transparent;
      border-top-left-radius: calc(0.5em / 2.1);
    }
}
  
	.lst01 {
		counter-reset: counter-ol;
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			color: $siteCol-brn04;
			li {
				padding: 1em;
				border-radius: calc(0.3em / 1.7);
				background-color: $siteCol-brn02;
				position: relative;
				&:nth-child(n+2) {
					margin-top: calc(0.8em / 1.7);
				}
				&::before {
					position: absolute;
					top: 1em;
					left: 1em;
				}
				li {
					background-color: $siteCol-wht01;
				}
			}
			& > li {
				ul, ol {
					margin-top: 1em;
					position: relative;
				}
			}
		}
	}
	ul.lst01 {
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			& > li {
				$base-pdng-l: 1em;
				padding-left: $base-pdng-l * 2;
				&::before {
					content: '・';
				}
				ul, ol {
					width: calc(100% + #{$base-pdng-l});
					left: -#{$base-pdng-l};
				}
			}
		}
	}
	ol.lst01 {
		&:not(.sub-top-lst):not(.tab-lst):not(.bak-num-lst) {
			& > li {
				$base-pdng-l: '3.6em / 1.7';
				padding-left: calc(#{$base-pdng-l} + 1em);
				counter-increment: counter-ol;
				&::before {
					content: counter(counter-ol)'．';
					width: calc(#{$base-pdng-l});
					text-align: right;
				}
				ul, ol {
					width: calc(100% + #{$base-pdng-l});
					left: calc(-#{$base-pdng-l});
				}
			}
		}
	}
}
