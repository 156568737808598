/*フェロー 後で修正*/
.pg44{
  .mn-art td ul {
    display: flex;
    flex-wrap: wrap;
    li{
      width:30%;
      margin-top: 0!important;
    }
  }
}