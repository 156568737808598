// Contents side bar styles
// =============================================================================
.cnt-asd {
	&_ttl {
		color: $siteCol-f-wht;
		font-size: calc(1.9em / 1.7);
		background-color: $siteCol-rsj-grn;
		border-radius: calc(0.5em / 1.9) calc(0.5em / 1.9) 0 0;
		overflow: hidden;
	}
	&_ttl-lnk {
		padding: .5em .9em;
		color: inherit !important;
		opacity: 1 !important;
		display: flex;
		align-items: center;
		&::before {
			content: '';
			width: $icn-asd-lst-w;
			height: $icn-asd-lst-w;
			margin-right: calc(1.5em / 1.9);
			background: {
				image: imgUrl('img_icn_asd_lst.svg');
				repeat: no-repeat;
				position: right center;
				size: $icn-asd-lst-w $icn-asd-lst-w;
			}
			display: block;
			flex-shrink: 0;
			position: relative;
			top: calc(-0.1em / 1.9);
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		margin-top: 3em;
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		width: 100%;
		max-width: calc(30em / 1.7);
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		max-width: calc(27em / 1.7);
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		max-width: none;
		min-width: 0;
		margin-top: calc(5em / 1.7);
	}
}

.asd-lst {
	margin-top: calc(1.8em / 1.5);
	padding: 0 calc(2em / 1.5);
	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsnsJp-medium;
	}
	line-height: 1.4;
	.-lv01 &,
	.-lv02 & {
		margin-top: 0;
	}
	.-lv01 & {
		padding: 0 0 calc(0.8em / 1.5);
		font-size: 1em;
	}
	.-lv01:last-child > & {
		padding-bottom: 0;
	}
	.-lv02 & {
		padding: 0 calc(2em / 1.3) calc(1.4em / 1.3);
		font-size: calc(1.3em / 1.5);
	}
	&_itm {
		border-radius: calc(0.3em / 1.5);
		&:nth-child(n+2) {
			margin-top: calc(0.5em / 1.5);
		}
		&.-lv02 {
			background-color: $siteCol-l-grn01;
		}
		&.-lv03 {
			border-radius: calc(0.3em / 1.3);
			background-color: rgba($siteCol-f-wht, 0.6);
			&:nth-child(n+2) {
				margin-top: calc(0.5em / 1.3);
			}
		}
	}
	&_lnk {
		padding: calc(0.8em / 1.5) 0;
		color: $siteCol-gly02 !important;
		display: flex;
		align-items: baseline;
		opacity: 1 !important;
		transition: color $overTime $easeOutQuint !important;
		.-lv01 &,
		.-lv03 & {
			&::before {
				font-weight: $fwNsnsJp-medium;
				display: block;
				flex-shrink: 0;
				position: relative;
			}
		}
		.-lv01 & {
			&::before {
				content: '\e906';
				margin-right: calc(1em / 1.1);
				font: {
					family: icomoon;
					size: calc(1.1em / 1.5);
					weight: $fwNsrfJp-medium;
				}
				top: calc(-0.1em / 1.1);
			}
		}
		.-lv02 & {
			color: $siteCol-ash-grn01 !important;
		}
		.-lv02 > & {
			padding-right: calc(2.2em / 1.5);
			 &::before {
				visibility: hidden;
			}
		}
		.-lv03 & {
			padding: {
				right: calc(1em / 1.3);
				left: calc(1em / 1.3);
			}
			&::before {
				content: '\e907';
				margin-right: calc(1em / 0.7);
				font: {
					family: icomoon;
					size: calc(0.7em / 1.3);
					weight: $fwNsrfJp-medium;
				}
				top: calc(-0.2em / 0.7);
			}
		}
		 .is-actv > & {
			color: $siteCol-rsj-grn !important;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_lnk {
			&:hover {
				color: $siteCol-rsj-grn !important;
			}
		}
	}
}
