// FAQ styles
// =============================================================================
.faq-lst {
	&_itm {
		border-radius: calc(0.5em / 1.7);
		overflow: hidden;
		&:nth-child(n+2) {
			margin-top: calc(0.8em / 1.7);
		}
	}
	&_trm {
		padding: calc(1.4em / 1.9) calc(1.5em / 1.9);
		color: $siteCol-brn04;
		font-size: calc(1.9em / 1.7);
		line-height: 1.4;
		text-align: justify;
		background-color: $siteCol-brn03;
		display: flex;
		align-items: center;
		cursor: pointer;
		&::before {
			content: '\e90b';
			font: {
				family: icomoon;
				weight: $fwNsnsJp-medium;
			}
			line-height: 1;
			flex-shrink: 0;
		}
	}
	&_ttl {
		margin-right: auto;
	}
	&_tgl {
		width: calc(1.3em / 1.9);
		height: calc(0.3em / 1.9);
		margin-left: calc(1.5em / 1.9);
		background-color: currentColor;
		display: block;
		flex-shrink: 0;
		position: relative;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			background-color: inherit;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(-90deg);
			transition: transform $tglTime $easeOutQuint;
		}
		.is-shw & {
			&::before {
				transform: rotate(0);
			}
		}
	}
	&_dsc {
		background-color: $siteCol-brn02;
		display: none;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&_trm {
			&::before {
				margin-right: calc(1em / 2.9);
				font-size: $fz29;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&_trm {
			transition: opacity $overTime $easeOutQuint;
			&::before {
				margin-right: calc(1em / 3.5);
				font-size: $fz35;
			}
			&:hover {
				opacity: 0.4;
			}
		}
		&_dsc {
			&::before {
				margin-right: calc(-2.8em / 3.5);
				width: calc(5.5em / 3.5);
				height: calc(5.5em / 3.5);
			}
		}
	}
}

.ans-otr {
	padding: calc(1.5em / 1.7);
	display: flex;
	align-items: center;
	&::before {
		content: '\e90a';
		color: $siteCol-pnk02;
		font: {
			family: icomoon;
			weight: $fwNsnsJp-medium;
		}
		line-height: 1;
		border-radius: 50%;
		background-color: $siteCol-wht01;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 5;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&::before {
			margin-right: calc(-2.5em / 2.9);
			width: calc(4.9em / 2.9);
			height: calc(4.9em / 2.9);
			font-size: $fz29;
		}
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		flex-direction: column;
		&::before {
			margin: 0 0 calc(-2.5em / 2.9);
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&::before {
			margin-right: calc(-2.8em / 3.5);
			width: calc(5.5em / 3.5);
			height: calc(5.5em / 3.5);
			font-size: $fz35;
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-m', 'tblt-l') { // tablet size = M - L //
		flex-direction: column;
		&::before {
			margin: 0 0 calc(-2.8em / 3.5);
		}
	}
}

.ans {
	padding: calc(2em / 1.7) calc(2em / 1.7);
	border-radius: calc(0.5em / 1.7);
	background-color: $siteCol-wht01;
	* + ul,
	* + ol {
		margin-top: 0.5em;
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		padding-left: calc(2.6em / 1.5);
	}
	@include mediaQueries-maxW('mbl-m') { // mobile size = M //
		padding: calc(2.4em / 1.5) calc(2em / 1.7) calc(2em / 1.7);
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		padding-left: calc(3em / 1.7);
	}
	@include mediaQueries-minW-to-maxW('tblt-m', 'tblt-l') { // tablet size = M - L //
		padding: calc(2.4em / 1.7) calc(2em / 1.7) calc(2em / 1.7);
	}
}
