// Sub top styles
// =============================================================================
.sub-top-lst-otr {
	padding: 0 calc(2em / 1.7) calc(2em / 1.7);
	// display: none;
}

.sub-top-lst {
	line-height: 1.4;
	&.-ch-lst {
		padding: calc(1em / 1.7) calc(1.5em / 1.7);
		border-radius: calc(0.3em / 1.7);
		background-color: $siteCol-wht01;
		display: flex;
		flex-wrap: wrap;
	}
	&_itm {
		// padding: 0 !important;
		&.-lv01 {
			border-radius: calc(0.3em / 1.7);
			background-color: $siteCol-brn02;
			&:nth-child(n+2) {
				margin-top: calc(1.5em / 1.7);
			}
		}
		&.-lv02 {
			margin: calc(0.6em / 1.7) calc(1.5em / 1.7);
		}
	}
	&_flx {
		display: flex;
		align-items: center;
	}
	&_lnk {
		display: flex;
		align-items: baseline;
		opacity: 1 !important;
		transition: color $overTime $easeOutQuint !important;
    background:none !important;
		&::before {
			font-weight: $fwNsnsJp-medium;
			display: block;
			flex-shrink: 0;
			position: relative;
		}
		.sub-top-lst_flx > & {
			width: 100%;
			font-size: calc(2.1em / 1.7);
			padding: calc(2em / 2.1);
			color: $siteCol-brn04;
			&::before {
				content: '\e908';
				margin-right: calc(1.5em / 1.9);
				font: {
					family: icomoon;
					size: calc(1.9em / 2.1);
					weight: $fwNsrfJp-medium;
				}
			}
		}
		.-lv02 > & {
			color: $siteCol-gly02;
			font-weight: $fwNsrfJp-medium;
			&::before {
				content: '\e907';
				margin-right: 1em;
				font: {
					family: icomoon;
					size: calc(1em / 1.7);
					weight: $fwNsrfJp-medium;
				}
				top: calc(-0.35em / 1.7);
			}
		}
	}
}
