/*サイトマップ*/
.s_sitemap{
  column-count: 3;
  
  ul{
    + ul{margin-top: 1em;}
    li{
      margin: .5em 0 .5em 1em;
    }

    .lev1{
      > a{
        display: block;
        font-size: 1.1em;
        color: $siteCol-f-wht;
    		background-color: $siteCol-d-blu02;
    		border-radius: calc(0.5em / 1.9) calc(0.5em / 1.9) 0 0;
    		padding: .3em 1em;
        }
      + .lev1{
        margin-top: 2em;
      }
    }
  
    .lev2{
      
      > a::before {
      content: '\e906';
      margin-right: calc(1em / 1.1);
      font-family: icomoon;
      font-size: calc(1.1em / 1.5);
      font-weight: 500;
      top: calc(-0.1em / 1.1);
      }
      
      + .lev2{
        margin-top: 1em;
      }
    }
    
    .lev3{
      margin-left: 3em;
      font-size: 1.2rem;
    }

  }
  


}

@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
  .s_sitemap{
    column-count: 2;
  }

}
@include mediaQueries-maxW('mbl-m') { // mobile size = M //
  .s_sitemap{
    column-count: 1;
  }
}

/*学術講演会*/
.tmp11 .mn-art{
  table {
    border-spacing: calc(0.8em / 3);
  }
  th {
    padding: .25em;
    font-size: 1.3rem;
  }
  td {
    .bt, .bt2 {	margin: .25em auto;}
    &.grn {background-color: $siteCol-wht01 !important;}
    &.grn.disable a{opacity: .5!important; pointer-events: none; }
    padding: .25em;
  }
  .contlist_past table {
      font-size: 1.5rem;
      td:nth-of-type(1),
      td:nth-of-type(3),
      td:nth-of-type(4){
        text-align: center;
      }
  }
}

.tmp5,
.tmp33 {
  time {
    font-size: .9em;
    transform: translateY(-2em);
    display: block;
    text-align: right;
    padding-right: 2em;
  }
}


.mn-art{
  
  .pg22 & , .pg107 &{
    .frame{
      margin: 1em 0;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1em;
      figure{
        padding: 10px;
        width: calc(100% / 3 - 1px);
      }
      img { height: 60px;}
      figcaption{ display: block;}
    } 
  }
}

/*共催行事*/
table.tbl_kyosai {
  th {
    &:nth-of-type(1){width:30%;}
    &:nth-of-type(2){width:30%;}
    &:nth-of-type(3){width:16%;}
    &:nth-of-type(4){width:12%;}
    &:nth-of-type(5){width:12%;}
  }
  td {
    &:nth-of-type(n+3){text-align: center;}
  }
}

.pg48 .mn-art{
  table.tbl_thin th{
      &:nth-of-type(1){width:3em;}
  }
}
