.container{

  .list_box{
    
    .list_item{
      padding: 10px 0;
      border-top: 1px dotted #000;
      display: flex; align-items: center;
      justify-content: space-between;
      > div{
      width: calc(100% - 220px )
      }
/*
      .infobox{
        margin-left: 20px; font-size: .9em;
        color: red;
       }
*/
      ._lead{padding-top: 5px; font-size: .8rem;}
//       .infobox_inner{margin-left: 20px; font-size: .9em;} 
      time{ width: 80px; text-align: center;  font-size: .8rem;}
      h2{ font-size: 1rem; line-height: 1.5; margin: 0; }

      .bt{width: 100px; text-align: center; }
      a{font-size: .7rem; padding: 10px;}
/*
      ._cate{ width: 60px; background: #b0b0b0; color: #fff; padding: 3px; font-size: .7rem; margin-right: 10px; text-align: center;}
      ._news{ background: #1982c2;}
      ._history{ background: #ad8516;}
      ._rij{ background: #51a844;}
      

      
      ._author{ width: 20%;  min-width: 120px; font-size: .8rem;}
      ._year{ width: 120px;  font-size: .8rem; text-align: center;}*/

     }
    
  }
  
  .contlist_box{ 
     .list_item{
       h2{ font-size: 1.2rem; line-height: 1.5; margin: .5em 0; }
       margin-bottom: 30px;
       .bt{ display: block; padding-top: 10px;}
      }
  }
   
}


/*
.c_rij .container{

  .list_box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  }
  
  .list_item{
    width: 49%; 
  }
}

@media screen and (max-width:768px) {
 .c_rij .container{

  .list_box{
      display: block;
  }
  
  .list_item{
    width: 100%; 
  }
} 
  
}
*/






