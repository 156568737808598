// General button styles
// =============================================================================
.btn {
	width: 100%;
	line-height: 1.5;
	display: block;
	&.-sns-btn {
		width: $sns-icn-size;
		height: $sns-icn-size;
		line-height: 0;
	}
	&.-login-btn{
		width: 7.5em;
		height: 30px;
		color: $siteCol-f-wht;
	 	font: {
			size: .8em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		border-radius: 100em;
		background-color: $siteCol-rsj-grn;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	&.-nav-btn {
		width: 7.5em;
		height: 30px;
		color: $siteCol-f-wht;
	 	font: {
			size: .8em;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		border-radius: 100em;
		background-color: $siteCol-d-blu02;
		//background-color: $siteCol-pnk02;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&::after {
			content: '';
			width: $icn-nav-size;
			height: $icn-nav-size;
			margin-left: calc(0.8em / 1.3);
			background: {
				image: imgUrl('img_icn_nav.svg');
				repeat: no-repeat;
				position: center;
				size: $icn-nav-size $icn-nav-size;
			}
			display: block;
		}
		&.-nav-cls-btn {
			width: calc(18em / 1.5);
			height: calc(3.5em / 1.5);
			margin-top: calc(3em / 1.5);
		 	font-size: calc(1.5em / 1.7);
			&::after {
				content: '';
				width: $icn-nav-cls-size;
				height: $icn-nav-cls-size;
				background: {
					image: imgUrl('img_icn_nav_cls.svg');
					size: $icn-nav-cls-size $icn-nav-cls-size;
				}
				transform: rotate(45deg);
			}
		}
		.-prm-inr & {
			margin-left: calc(3em / 1.3);
		}
	}
	&.-dtl-btn {
		padding: calc(0.4em / 1.5) calc(1.5em / 1.5);
	 	font: {
			size: calc(1.5em / 1.7);
			weight: $fwNsnsJp-bold;
		}
		border-radius: 100em;
		background-color: $siteCol-rsj-grn;
		overflow: hidden;
		.cnt-bnr-lst & {
			max-width: calc(20em / 1.5);
			margin-top: calc(2em / 1.5);
			background-color: $siteCol-f-wht;
		}
	}
	&.-more-btn {
		width: auto;
		height: calc(2.6em / 1.3);
		padding: 0 calc(1.8em / 1.5);
		margin: 0 calc(2em / 1.3) 0 auto;
		color: $siteCol-f-wht;
	 	font: {
			size: $fz15;
			weight: $fwNsnsJp-bold;
		}
		line-height: 1;
		border-radius: 100em;
		background-color: $siteCol-d-blu02;
		//background-color: $siteCol-pnk02;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	&.-acrd-btn {
		width: calc(14em / 1.5);
		height: calc(2.4em / 1.5);
		margin: 0 0 calc(2em / 1.5) auto;
		color: $siteCol-f-wht;
	 	font: {
			size: calc(1.5em / 1.7);
			weight: $fwNsnsJp-bold;
		}
		border-radius: 100em;
		background-color: $siteCol-rsj-grn;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		.is-shw & {
			&::before {
				content: attr(data-tgl);
			}
		}
	}
	&.-gmap-btn {
		width: calc(11.4em / 1.3);
		border-radius: 100em;
		background-color: $siteCol-f-wht;
		opacity: 0.5;
	}
	&.-jstage {
		height: 5 / 1.6 + 0em;
		max-width: 36 / 1.6 + 0em;
		margin-top: 4 / 1.6 + 0em;
		font-weight: $fwNsnsJp-bold;
		border-radius: 0.3 / 1.6 + 0em;
		background-color: $siteCol-rsj-grn;
		overflow: hidden;
	}
	&_lnk,
	&_wrp {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&_lnk {
		.-tw-btn & {
			color: $siteCol-k-blk !important;
			background: none;
		}
		.-fb-btn & {
			color: $siteCol-fb-blu !important;
		}
		.-tw-btn &,
		.-fb-btn & {
			.gen-foot & {
				color: $siteCol-f-wht !important;
			}
		}
		.-dtl-btn & {
			color: $siteCol-f-wht !important;
			justify-content: space-between;
			.cnt-bnr-lst & {
				color: $siteCol-d-blu01 !important;
				justify-content: center;
			}
			&::after {
				content: '\e909';
				margin-left: 0.5em;
				font: {
					family: icomoon;
					size: calc(1.65em / 1.4);
					weight: $fwNsrfJp-medium;
				}
				line-height: 1;
			}
		}
		.-gmap-btn & {
			padding: calc(0.1em / 1.3) 0;
			color: $siteCol-d-grn01 !important;
		}
		.-jstage & {
			color: $siteCol-f-wht !important;
			& > i {
				margin-left: 0.4em;
			}
		}
	}
	&_wrp {
		.-more-btn &,
		.-acrd-btn & {
			width: auto;
			height: auto;
			display: inline;
			.is-shw & {
				display: none;
			}
		}
	}
	&_icn {
		width: 100%;
		display: block;
		flex-shrink: 0;
		&.-arw-icn {
			width: $icn-dtl-arw-w;
			margin-left: calc(0.8em / 1.5);
			.ua-ie-lgcy & {
				height: 1.6 / 1.5 + 0em;
			}
		}
		&.-gmap-icn {
			width: $icn-gmap-w;
			margin-right: calc(0.4em / 1.3);
			.ua-ie-lgcy & {
				height: 1.5 / 1.3 + 0em;
			}
		}
	}
	&_tgl {
		width: calc(1.3em / 1.5);
		height: calc(0.3em / 1.5);
		line-height: 1;
		background-color: currentColor;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background-color: inherit;
			display: block;
			transform: rotate(-90deg);
			transition: transform $tglTime $easeOutQuint;
			.is-shw & {
				transform: rotate(0);
			}
		}
		.-acrd-btn & {
			margin: 0 0 0 calc(1em / 1.5);
		}
	}
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		&.-nav-btn {
			.-prm-inr & {
				display: none;
			}
			.-scnd-inr & {
				height: 30px;
				margin: 6px 0 6px 6px;
			}
		}
		&.-more-btn {
			width: calc(3.1em / 1.5);
			height: calc(3.1em / 1.5);
			padding: 0;
			border-radius: 50%;
		}
		&.-jstage {
			margin: {
				right: auto;
				left: auto;
			}
		}
		&_wrp {
			.-more-btn & {
				display: none;
			}
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.-nav-btn {
			.-scnd-inr & {
				display: none;
			}
		}
		&.-more-btn {
			.is-shw & {
				&::after {
					content: attr(data-tgl);
				}
			}
		}
		&.-gmap-btn {
			transition: opacity $overTime $easeOutQuint;
			&:hover {
				opacity: 1;
			}
		}
		&_tgl {
			margin-right: calc(1em / 1.5);
		}
	}
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-m') { // tablet size = S - M //
		&.-nav-btn {
			.-prm-inr & {
				display: none;
			}
			.-scnd-inr & {
				height: 30px;
				margin: 8px 0 8px 10px;
				display: flex;
			}
		}
		&.-jstage {
			margin: {
				right: auto;
				left: auto;
			}
		}
	}
}

h2 + .btn.-acrd-btn {margin-top: -1em;}
h3 + .btn.-acrd-btn {margin-top: -.7em;}

.fxd-bnt-otr {
 	opacity: 0;
 	visibility: hidden;
	transition:
		opacity $navShwTime $easeOutQuint,
		visibility $navShwTime $easeOutQuint,
		transform $navShwTime $easeOutQuint;
	.is-shw & {
		opacity: 1;
		visibility: visible;
		transition:
			opacity $navShwTime $navShwTime $easeOutQuint,
			visibility $navShwTime $navShwTime $easeOutQuint,
			transform $navShwTime $navShwTime $easeOutQuint;
	}
}

.more {
	margin: 1em auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	&.center {
		justify-content: center;
	}
	&.left {
		justify-content: flex-start;
	}
/*
	&.right {
		justify-content: flex-end;
	}
*/
	.rbgk-tm-line & {
		margin: {
			block: 0;
			inline: 0;
		}
	}
	a {
		padding: calc(0.4em / 1.5) 1em;
		color: $siteCol-f-wht !important;
	 	font: {
			size: calc(1.5em / 1.7);
			weight: $fwNsnsJp-bold;
		}
		line-height: 1.5;
		border-radius: 100em;
		background: $siteCol-d-blu02;
		//background-color: $siteCol-pnk02;
		display: flex;
		align-items: baseline;
		justify-content: center;
		&::after {
			content: '\e909';
			margin-left: calc(0.8em / 1.6);
		 	font: {
				family: icomoon;
				size: calc(1.6em / 1.5);
				weight: $fwNsnsJp-medium;
			}
			line-height: 1;
			position: relative;
			top: calc(0.15em / 1.6);
		}
		.rbgk-tm-line & {
			&::after {
				content: '\e909';
				top: calc(0.15em / 1.6);
			}
		}
	}
	&.grn {
		a {
			background-color: $siteCol-rsj-grn;
		}
	}
}

.bt, .bt2, .bt3{
	margin: 1em auto;
	color: $siteCol-f-wht !important;
 	font: {
		size: calc(1.5em / 1.7);
		weight: $fwNsnsJp-bold;
	}
	line-height: 1.5;
	background: $siteCol-d-blu02;
  //background-color: $siteCol-pnk02;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&.grn {
		background-color: $siteCol-rsj-grn;
	}
	&.blk {
		background-color: $siteCol-k-blk;
	}
	&.nmg {
		margin: 0 auto;
	}
	a {
    background:none;
		width: 100%;
		color: inherit !important;
		opacity: 1 !important; 
		display: flex;
		align-items: center;
		justify-content: center;
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		transition: opacity $overTime $easeOutQuint;
		&:hover {
			opacity: 0.4;
		}
	}
}

.bt {
	padding: .4em 1em;
	max-width: 15em;
	border-radius: 100em;
}

.bt2 {
	padding: .6em 1em;
	max-width: 20em;
	border-radius: .3em;
	overflow: hidden;
	.tmp9 &,
	.tmp28 & {
		margin: 0 auto;
		padding: 0;
		border-radius: .2em;
	}
	a {
		.tmp9 &,
		.tmp28 & {
			align-items: stretch;
			justify-content: flex-start;
			&::after {
				width: calc(4.5em / 2);
				padding: 0;
				font-size: calc(2em / 1.5);
				background-color: $siteCol-grn01;
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				order: -5;
				top: 0;
			}
			& > span {
				width: 100%;
				padding: calc(1.15em / 1.5) 1em;
				text-align: center;
			}
		}
		.tmp28 & {
			& > span {
				padding: calc(0.8em / 1.5) 1em;
			}
		}
	}
}

.bt3 {
	padding: .6em 1em;
	max-width: 35em;
	border-radius: .3em;
	overflow: hidden;
}

td {
	&.bt {
		border-radius: 0;
		background-color: transparent !important;
		opacity: 1 !important;
		display: table-cell;
		&.grn {
			a {
				margin: 0.5em auto;
				padding: calc(0.6em / 1.5) 1em;
				border-radius: calc(0.3em / 1.5);
				background-color: $siteCol-rsj-grn;
				&::before {
					display: none;
				}
			}
		}
	}
	a.bt {
		border-radius: calc(0.3em / 1.5);
	}
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		&.bt {
			&.grn {
				a {
					transition: opacity $overTime $easeOutQuint;
					&:hover {
						opacity: 0.4 !important;
					}
				}
			}
		}
	}
}

.btn-lst {
	.tmp9 & {
		margin-top: calc(3.4em / 1.7);
	}
	.tmp28 & {
		margin-top: calc(2em / 1.7);
	}
	&_itm {
		padding-left: 0 !important;
		&:nth-child(n+2) {
			margin-top: calc(0.6em / 1.7) !important;
		}
		&::before {
			display: none;
		}
	}
}

.pagenation {
  color: $siteCol-gly06;
  margin-top: 2em;
  text-align: center;
  span > span{
    color: $siteCol-blk01;
  }
  span > span, span > a{
    display: inline-block;
    padding: .2em .5em;
    margin: .2em;
    background: $siteCol-gly01;
  }
}

.advpub-btn {
	margin: 2em auto 0;
	font-size: 1.1em;
	.advpub-cpy + & {
		margin-top: 1em;
	}
}